/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.typo-heading-1 {
  font-size: 3rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
}
.typo-heading-2 {
  font-size: 2.4rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
}
.typo-heading-3 {
  font-size: 2rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
  letter-spacing: 0.5px;
}
.typo-heading-4, .tab-item.active {
  font-size: 1.6rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
}
.typo-body-1, .input-select-current, .btn {
  font-size: 1.6rem;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
}
.typo-body-2, .alert {
  font-size: 1.4rem;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
}
.typo-body-2-noquery, .table-report-time-item.current {
  font-size: 1.4rem;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
}
.typo-body-3 {
  font-size: 1.2rem;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
}
.typo-body-3-noquery {
  font-size: 1.2rem;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
}
.typo-dark-gray, .alert {
  color: #333e48;
}
.typo-white {
  color: #fff;
}
.typo-blue {
  color: #004d71;
}
.typo-sky {
  color: #36b9da;
}
.typo-dark-sky {
  color: #2487a0;
}
.typo-wolf-gray {
  color: #778998;
}
.typo-gun-metal {
  color: #5c6670;
}
.typo-concrete {
  color: #b4c6d4;
}
.typo-sky {
  color: #36b9da;
}
.typo-bold, .table-report-time-item.current, .input-select-list-item:hover, .input-select-list-item:focus, .btn {
  font-weight: bold;
}
.typo-regular {
  font-weight: normal;
}
.typo-textlink, .typo-textlink:active, .typo-textlink:link, .typo-textlink:visited {
  color: #36b9da;
  transition: color 0.25s;
}
.typo-textlink:hover, .typo-textlink:active:hover, .typo-textlink:link:hover, .typo-textlink:visited:hover {
  text-decoration: underline;
  color: #2487a0;
}
.typo-textlink *, .typo-textlink:active *, .typo-textlink:link *, .typo-textlink:visited * {
  color: #36b9da;
}
.typo-textlink-coral, .typo-textlink-coral:active, .typo-textlink-coral:link, .typo-textlink-coral:visited {
  font-size: 1.4rem;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
  color: #ff5000;
  transition: color 0.25s;
}
.typo-textlink-coral:hover, .typo-textlink-coral:active:hover, .typo-textlink-coral:link:hover, .typo-textlink-coral:visited:hover {
  text-decoration: underline;
  color: #e74900;
}
.typo-textlink-coral:hover *, .typo-textlink-coral:active:hover *, .typo-textlink-coral:link:hover *, .typo-textlink-coral:visited:hover * {
  color: #e74900;
}
.typo-textlink-coral *, .typo-textlink-coral:active *, .typo-textlink-coral:link *, .typo-textlink-coral:visited * {
  transition: color 0.25s;
  color: #ff5000;
}
.typo-capitalize {
  text-transform: capitalize;
}
.typo-uppercase, .btn {
  text-transform: uppercase;
}
.typo-lowercase {
  text-transform: lowercase;
}

.text-trim-1 {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

i {
  display: inline-block;
  position: relative;
  transition: 0.25s;
}
i:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  display: inline-block;
  background-color: #333e48;
  transition: transform 0.25s;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}
i.icon-small {
  width: 14px;
  height: 14px;
}
i.icon-small:before {
  width: 14px;
  height: 14px;
}
i.icon-medium {
  width: 20px;
  height: 20px;
}
i.icon-medium:before {
  width: 20px;
  height: 20px;
}
i.icon-large {
  width: 28px;
  height: 28px;
}
i.icon-large:before {
  width: 28px;
  height: 28px;
}
i.icon-box {
  background-color: #fff;
  box-shadow: 0 2px 14px -7px rgba(51, 62, 72, 0.6);
  border: solid 0.5px #f7f8ff;
  border-radius: 10px;
}
i.icon-box-no-shadow {
  box-shadow: none;
}
i.icon-box-no-border {
  border: 0;
}
i.icon-box-small {
  width: 34px;
  height: 30px;
}
i.icon-box-medium {
  width: 43px;
  height: 40px;
}
i.icon-box-large {
  width: 45px;
  height: 45px;
}
i.icon-box-sky {
  background-color: rgba(54, 185, 218, 0.2);
  border: 0;
}
i.icon-box-coral {
  background-color: #ff5000;
  border: 0;
}
i.icon-box-red {
  background-color: #e30039;
}
i.icon-box-green {
  background-color: #02d57c;
}
i.icon-dark-gray:before {
  background-color: #333e48;
}
i.icon-white:before, .btn-primary i.btn-icon:before {
  background-color: #fff;
}
i.icon-sky:before {
  background-color: #36b9da;
}
i.icon-coral:before, .header-user:hover i.header-user-chevron:before, .btn-secondary i.btn-icon:before {
  background-color: #ff5000;
}
i.icon-gun-metal:before {
  background-color: #5c6670;
}
i.icon-concrete:before, .btn-secondary.disabled i.btn-icon:before {
  background-color: #b4c6d4;
}
i.icon-red:before {
  background-color: #e30039;
}
i.icon-anim:hover {
  transform: scale(1.1);
}
i.icon-anim:hover.icon-dark-gray:before {
  background-color: #ff5000;
}
i.icon-anim:hover.icon-sky:before {
  background-color: #2487a0;
}
i.icon-anim:hover.icon-coral:before, .header-user:hover i.icon-anim.header-user-chevron:hover:before, .btn-secondary i.icon-anim.btn-icon:hover:before {
  background-color: #e74900;
}
i.icon-anim:hover.icon-gun-metal:before {
  background-color: #333e48;
}
i.icon-anim:active {
  transform: scale(0.9);
}
i.icon-anim:active.icon-box {
  background-color: #fbfcff;
}
i.icon-anim:active.icon-box.icon-box-coral {
  background-color: #e74900;
}
i.icon-clickable {
  cursor: pointer;
}
i.icon-disabled {
  pointer-events: none;
  cursor: auto;
}
i.icon-disabled:before {
  background-color: #b4c6d4 !important;
}
i.icon-disabled.icon-box:hover, i.icon-disabled.icon-box:active {
  background-color: #fff;
}
i.icon-alperia:before {
  mask-image: url("./../assets/imgs/alperia.svg");
  background-color: #778998;
}
i.icon-meter:before {
  mask-image: url("./../assets/imgs/meter-sky.svg");
}
i.icon-graph:before {
  mask-image: url("./../assets/imgs/graph-coral.svg");
}
i.icon-question:before {
  mask-image: url("./../assets/imgs/question.svg");
}
i.icon-chevron-up:before {
  mask-image: url("./../assets/imgs/chevron-up-coral.svg");
}
i.icon-chevron-left:before {
  mask-image: url("./../assets/imgs/chevron-left-coral.svg");
}
i.icon-chevron-right:before {
  mask-image: url("./../assets/imgs/chevron-right-coral.svg");
}
i.icon-chevron-down:before {
  mask-image: url("./../assets/imgs/chevron-down-dark-gray.svg");
}
i.icon-location:before {
  mask-image: url("./../assets/imgs/location-sky.svg");
}
i.icon-note:before {
  mask-image: url("./../assets/imgs/note-sky.svg");
}
i.icon-pencil:before {
  mask-image: url("./../assets/imgs/pencil-coral.svg");
}
i.icon-plus:before {
  mask-image: url("./../assets/imgs/plus-white.svg");
}
i.icon-reload:before {
  mask-image: url("./../assets/imgs/reload-red.svg");
}
i.icon-zoom-in:before {
  mask-image: url("./../assets/imgs/zoom-in-coral.svg");
}
i.icon-zoom-out:before {
  mask-image: url("./../assets/imgs/zoom-out-coral.svg");
}
i.icon-delete:before {
  mask-image: url("./../assets/imgs/delete-coral.svg");
}
i.icon-cross:before {
  mask-image: url("./../assets/imgs/cross-coral.svg");
}
i.icon-exit:before {
  mask-image: url("./../assets/imgs/exit-black.svg");
}
i.icon-rocket:before {
  mask-image: url("./../assets/imgs/rocket-dark-gray.svg");
}
i.icon-checkmark:before {
  mask-image: url("./../assets/imgs/checkmark-coral.svg");
}
i.icon-burger:before {
  mask-image: url("./../assets/imgs/burger-coral.svg");
}
i.icon-info:before {
  mask-image: url("./../assets/imgs/info-black.svg");
}
i.icon-file:before {
  mask-image: url("./../assets/imgs/file-text-black.svg");
}
i.icon-contrast:before {
  mask-image: url("./../assets/imgs/contrast-black.svg");
}
i.icon-moon:before {
  mask-image: url("./../assets/imgs/moon-black.svg");
}
i.icon-sun:before {
  mask-image: url("./../assets/imgs/sun-black.svg");
}
i.icon-gear:before {
  mask-image: url("./../assets/imgs/gear-black.svg");
}
i.icon-lightning:before {
  mask-image: url("./../assets/imgs/lightning-black.svg");
}
i.icon-clock:before {
  mask-image: url("./../assets/imgs/clock-black.svg");
}
i.icon-address-book:before {
  mask-image: url("./../assets/imgs/address-book-black.svg");
}
i.icon-paperclip:before {
  mask-image: url("./../assets/imgs/paperclip-black.svg");
}
i.icon-phone-out:before {
  mask-image: url("./../assets/imgs/phone-out-black.svg");
}
i.icon-warning:before {
  mask-image: url("./../assets/imgs/warning-black.svg");
}
i.icon-bell-ringing:before {
  mask-image: url("./../assets/imgs/bell-ringing-black.svg");
}
i.icon-bell-slash:before {
  mask-image: url("./../assets/imgs/bell-slash-black.svg");
}

button {
  background-color: inherit;
  border: initial;
  font-size: inherit;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
ul li,
ol li {
  display: inline-block;
}

i {
  font-style: normal;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

html {
  font-size: 10px;
}

body {
  min-height: 100vh;
  font-family: Arial;
  color: #333e48;
}

.page-spacing {
  margin-left: 30px;
  margin-right: 30px;
}

.loader-container, .modal-config-loader, .loader-notes .notebar-note-list .notebar-note-group,
.loader-notes .notebar-note-list .notebar-note, .loader-bde .table-bde-body, .loader-dashboard .table-preview-body, .loader-recap .table-preview-body, .loader-report .table-bde-body, .loader-report .chart .chartjs,
.loader-report .chart .chart-footer, .loader-report .table-report-body-row {
  opacity: 0.2 !important;
  transition: opacity 0.25s;
  pointer-events: none;
}

.loader-spinner, .modal-config-loader:after, .loader-notes .notebar-note-list:after, .loader-bde .table-bde:after, .loader-dashboard .table-preview:after, .loader-recap .table-preview:after, .loader-report .table-bde:after, .loader-report .chart:after, .loader-report .table-report:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  width: 25px;
  height: 25px;
  background-color: transparent;
  border-style: solid;
  border-color: #36b9da;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  animation: loaderSpinner 1s linear infinite;
}

@keyframes loaderSpinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.loader-report .table-report {
  position: relative;
}
.loader-report .chart {
  position: relative;
}
.loader-report .table-bde {
  position: relative;
}
.loader-recap .table-preview {
  position: relative;
}
.loader-dashboard .table-preview {
  position: relative;
}
.loader-bde .table-bde {
  position: relative;
}
.loader-notes .notebar-note-list {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}

body.modal-open {
  overflow-y: hidden;
}

/* .outline-sky:focus {
  outline: solid $sky 1.5px;
} */
/* :focus {
  border: solid $sky 1.5px !important;
  border-radius: inherit;
} */
.accordion.open > .accordion-head .accordion-chevron:before {
  transform: translate(-50%, -50%) rotateX(180deg);
}
.accordion.open > .accordion-body {
  display: block;
}
.accordion-head {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.accordion-head-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.accordion-chevron {
  margin-left: 10px;
}
.accordion-chevron:before {
  transition: transform 0.25s;
}
.accordion-body {
  display: none;
}
.accordion-body-inner {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
}
@media (max-width: 1499.98px) {
  .accordion-body-inner .accordion-body-scroll {
    overflow-x: auto;
  }
}
.accordion.accordion-big > .accordion-head {
  padding: 0;
}
.accordion.accordion-big > .accordion-head .accordion-head-content {
  flex-grow: 1;
}
.accordion.accordion-big > .accordion-head .accordion-head-subtitle {
  margin-left: 20px;
}
.accordion.accordion-big > .accordion-head .accordion-head-button {
  margin-left: 10px;
}
.accordion.accordion-big .accordion-big-margin {
  margin-left: 40px;
  margin-right: 40px;
}
@media (max-width: 1499.98px) {
  .accordion.accordion-big .accordion-big-margin {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.accordion.accordion-big .accordion-big-padding {
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 1499.98px) {
  .accordion.accordion-big .accordion-big-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.accordion-separator {
  width: 100%;
  height: 1px;
  background-color: rgba(218, 223, 228, 0.5);
  border-style: none;
  border-width: 0;
}
@media (max-width: 1499.98px) {
  .accordion-separator {
    width: calc(100% - 20px );
  }
}

.alert {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  z-index: 50;
}
.alert-speed-slow {
  animation-name: fade-in;
  animation-duration: calc( 0.25s * 3);
  animation-timing-function: ease-in;
}
.alert-speed-fast {
  animation-name: fade-in;
  animation-duration: 0.25s;
  animation-timing-function: ease-in;
}
.alert-icon {
  margin-right: 8px;
  width: 55px;
}
.alert-controls {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.alert-checkbox {
  margin: 0 22px 0 0;
  width: max-content;
}
.alert-close {
  cursor: pointer;
}
.alert-theme-info {
  border-color: #36b9da;
  background-color: #eafbff;
}
.alert-theme-info .alert-checkbox .checkbox-checkmark {
  border: solid #36b9da 1px;
}
.alert-theme-error {
  border-color: #ff516d;
  background-color: #ffebee;
}
.alert-theme-success {
  border-color: #02d57c;
  background-color: #ecfff7;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.alert-autoclose {
  overflow: hidden;
}
.alert-autoclose-animation {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.1));
  opacity: 0;
  z-index: -1;
  animation: loading linear;
}
@keyframes loading {
  from {
    width: 0;
    opacity: 0;
  }
  to {
    width: 100%;
    opacity: 0.5;
  }
}

.auction-config {
  position: relative;
}
.auction-config-heading {
  margin-bottom: 15px;
}
.auction-config-heading:not(:first-child) {
  margin-top: 50px;
}
.auction-config-field-set {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.auction-config-field-label {
  width: 150px;
}
.auction-config-field-input {
  width: 400px;
}
.auction-config-field-controls {
  margin-left: 30px;
}
.auction-config-field-controls i:not(:first-child) {
  margin-left: 10px;
}
.auction-config-alert-box {
  position: absolute;
  top: -140px;
  left: 0;
  width: 100%;
}

.btn {
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.25s, background-color 0.25s, border-color 0.25s;
  padding: 15px 35px;
  letter-spacing: 0.89px;
}
.btn:focus, .btn:active {
  border: solid #36b9da 2px !important;
  outline: none;
}
.btn:hover .btn-text {
  transform: scale(1.1);
}
.btn:hover i.btn-icon:before {
  transform: translate(-50%, -50%) scale(1.1);
}
.btn:active .btn-text {
  transform: scale(0.9);
}
.btn:active i.btn-icon:before {
  transform: translate(-50%, -50%) scale(0.9);
}
.btn.disabled {
  pointer-events: none;
}
.btn-primary {
  background-color: #ff5000;
  color: #fff;
  border: solid #ff5000 2px;
}
.btn-primary:hover {
  background-color: #e25413;
  border: solid #e25413 2px;
}
.btn-primary.disabled {
  background-color: #b4c6d4;
  border-color: #b4c6d4;
}
.btn-secondary {
  background-color: #fff;
  color: #ff5000;
  border: solid #fff 2px;
  box-shadow: 0 2px 14px -7px rgba(51, 62, 72, 0.6);
}
.btn-secondary:hover {
  color: #e25413;
}
.btn-secondary:hover i.btn-icon:before {
  background-color: #e25413;
}
.btn-secondary.disabled {
  color: #b4c6d4;
  border: solid #b4c6d4 2px;
  box-shadow: none;
}
.btn-text {
  flex-grow: 1;
  transition: transform 0.25s;
}

.card, .auction-page-guide-list-item-example, .tab-item.active {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
  padding: 16px;
}
.card.no-pad, .no-pad.auction-page-guide-list-item-example, .tab-item.active {
  padding: 0;
}
.card-border {
  border: solid 1.5px rgba(218, 223, 228, 0.58);
}
.card.no-shadow, .no-shadow.auction-page-guide-list-item-example, .no-shadow.tab-item.active {
  box-shadow: none;
}

.chart-doughnut-canvas-wrapper {
  position: relative;
  margin: auto;
}
.chart-doughnut-info, .chart-doughnut-track {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chart-doughnut-info {
  z-index: 2;
}
.chart-doughnut-info-percentage {
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart-doughnut-info-percentage-value {
  line-height: 1;
}
.chart-doughnut-info-duration {
  display: flex;
}
.chart-doughnut-info-duration-since, .chart-doughnut-info-duration-value {
  margin-right: 3px;
}
.chart-doughnut-track {
  width: 200px;
  height: 200px;
  background-color: transparent;
  border: solid #eceeff 20px;
  border-radius: 200px;
  z-index: 0;
}
.chart-doughnut canvas {
  position: relative;
  z-index: 1;
}
.chart-doughnut-legend {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.chart-doughnut-legend-item {
  display: flex;
  align-items: center;
}
.chart-doughnut-legend-item-sample {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 4px;
}
.chart-doughnut-legend-item-sample.nominal {
  background-color: #36b9da;
}
.chart-doughnut-legend-item-sample.no-data {
  background-color: #778998;
}
.chart-doughnut-legend-item-sample.warning {
  background-color: #ff5000;
}
.chart-doughnut-legend-item-sample.critical {
  background-color: #e30039;
}

.chart {
  position: relative;
}
.chart-canvas-wrapper {
  width: 100%;
  overflow-x: auto;
}
.chart-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 15px;
}
.chart-legend {
  display: flex;
  align-items: center;
}
.chart-legend-list {
  display: flex;
  align-items: center;
}
.chart-legend-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: #fff;
  box-shadow: 0 2px 14px -7px rgba(51, 62, 72, 0.6);
  border-radius: 10px;
  border: solid 0.5px #f7f8ff;
  padding: 0 10px;
  margin-left: 10px;
  cursor: pointer;
}
.chart-legend-list-item-label {
  transition: color 0.25s;
}
.chart-legend-list-item-sample {
  width: calc( 12px - 1px );
  height: calc( 12px - 1px );
  border-style: solid;
  border-width: 1px;
  border-radius: calc( 12px - 1px );
  margin-right: 6px;
  transition: background-color 0.25s;
}
.chart-legend-list-item:hover .chart-legend-list-item-label {
  color: #36b9da;
}
.chart-legend-list-item.inactive .chart-legend-list-item-sample {
  background-color: transparent !important;
}
.chart-tooltip {
  display: block;
  position: absolute;
  top: 5px;
  width: 124px;
  height: fit-content;
  padding: 14px 10px 20px 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
  opacity: 0;
  transition: opacity 0.25s, left 0.25s;
  pointer-events: none;
}
.chart-tooltip-head {
  line-height: 1;
  padding-bottom: 8px;
  border-bottom: solid #dadfe4 1px;
  margin-bottom: 8px;
}
.chart-tooltip-body {
  display: flex;
  justify-content: space-between;
}

ngb-datepicker {
  width: 260px;
  background-color: #fff;
  border: solid rgba(218, 223, 228, 0.5) 1px !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
}
ngb-datepicker .ngb-dp-header {
  margin: 10px 0 15px;
}
ngb-datepicker .ngb-dp-navigation-select > select:nth-child(1) {
  text-align: center;
  border: 0;
  font-weight: bold;
  font-size: 1.4rem;
  background-color: #fff;
}
ngb-datepicker ngb-datepicker-month {
  width: 260px;
  padding-bottom: 10px;
  font-size: 1.2rem;
}
ngb-datepicker .ngb-dp-week {
  padding: 0 15px !important;
}
ngb-datepicker .ngb-dp-weekdays {
  border: none;
  margin-bottom: 10px;
}
ngb-datepicker .ngb-dp-weekday {
  font-style: normal;
  text-transform: uppercase;
  color: #36b9da;
}
ngb-datepicker .ngb-dp-weekday,
ngb-datepicker .ngb-dp-day {
  width: 100%;
}
ngb-datepicker .ngb-dp-day > .btn-light {
  width: 100%;
}
ngb-datepicker .ngb-dp-day > .btn-light.bg-primary {
  position: relative;
  color: #fff;
  font-weight: bold;
  background-color: transparent !important;
}
ngb-datepicker .ngb-dp-day > .btn-light.bg-primary:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
  height: 22px;
  background-color: #36b9da;
  border-radius: 22px;
  z-index: -1;
}
ngb-datepicker .ngb-dp-today {
  position: relative;
  font-weight: bold;
}
ngb-datepicker .ngb-dp-today:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 22px;
  height: 22px;
  border: solid #36b9da 1px;
  border-radius: 22px;
  z-index: -1;
}

.header {
  width: 100%;
  height: 70px;
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
  position: fixed;
  z-index: 999;
}
.header-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.header-logo {
  width: 90px;
  height: 30px;
}
.header-left, .header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-burger {
  display: none;
}
@media (max-width: 1499.98px) {
  .header-burger {
    display: block;
  }
}
.header-menu {
  display: flex;
}
@media (max-width: 1499.98px) {
  .header-menu {
    position: absolute;
    top: 70px;
    left: -318px;
    width: 318px;
    height: calc( 100vh - 70px );
    background-color: #fff;
    flex-direction: column;
    padding: 30px 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: none;
    transition: left 0.25s, box-shadow 0.25s;
  }
  .header-menu.open {
    left: 0;
    box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
  }
  .header-menu.open .header-menu-overlay {
    opacity: 0.2;
  }
  .header-menu:not(.open) .header-menu-overlay {
    pointer-events: none;
  }
}
.header-menu-item {
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  transition: color 0.25s, background-color 0.25s;
}
@media (max-width: 1499.98px) {
  .header-menu-item {
    width: 100%;
    padding: 10px 48px;
    border-radius: 0;
    margin: 0 0 10px 0;
  }
}
.header-menu-item:hover {
  color: #36b9da;
}
.header-menu-item:hover .header-menu-item-icon:before {
  background-color: #36b9da;
}
.header-menu-item.active {
  background-color: #36b9da;
  color: #fff !important;
  font-weight: bold;
}
.header-menu-item.active:hover {
  background-color: #2487a0;
}
.header-menu-item.active .header-menu-item-icon:before {
  background-color: #fff;
}
.header-menu-item-icon {
  margin-left: 10px;
  transition: color 0.25s, background-color 0.25s;
}
.header-menu-item-icon:before {
  transition: color 0.25s, background-color 0.25s;
}
.header-menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: calc( 100vw + 318px );
  height: calc( 100vh - 70px );
  background-color: #333e48;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.25s;
}
.header-user {
  position: relative;
  margin-left: 50px;
}
.header-user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.header-user-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9f7eb;
  border: solid #02d57c 2px;
  border-radius: 50%;
}
.header-user-chevron {
  margin-left: 10px;
}
.header-user-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  padding: 10px 12px;
  cursor: initial;
}
.header-user-menu-item:not(:first-child) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8px;
  cursor: pointer;
}
.header-user-menu-item-name {
  border-bottom: solid #dadfe4 1px;
}
.header-user-menu-item-logout {
  align-items: center;
}
.header-user-menu-item i {
  margin-left: 10px;
}
.header-user-menu-item.disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
.header-user.open .header-user-menu {
  display: flex;
}
.header-user.open .header-user-chevron {
  transform: rotateX(180deg);
}
.header-language {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
}
@media (max-width: 1499.98px) {
  .header-language {
    padding-top: 30px;
    padding-left: 30px;
    border-top: solid #dadfe4 1px;
    margin: 50px 15px 0 15px;
  }
}
.header-language-item.active {
  color: #333e48;
}
.header-language-separator {
  color: #dadfe4;
}
.header-logout {
  margin-left: 15px;
}
.header-clock {
  display: flex;
  align-items: center;
  margin-right: 25px;
  padding: 10px 15px;
  border-radius: 10px;
  border: solid 1.5px #dadfe4;
}
.header-external-link {
  margin-right: 24px;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
.checkbox-input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}
.checkbox-checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 18px;
  height: 17px;
  background-color: #fff;
  border: solid #778998 1px;
  border-radius: 5px;
  transition: background-color 0.25s;
}
.checkbox:hover .checkbox-checkmark, .checkbox:focus .checkbox-checkmark {
  background-color: #f1f8fb;
}
.checkbox:hover .checkbox-checkmark:after, .checkbox:focus .checkbox-checkmark:after {
  background-color: #013d5a;
}
.checkbox-input:checked ~ .checkbox-checkmark:after {
  opacity: 1;
}
.checkbox-checkmark:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 10px;
  height: 10px;
  background-color: #2487a0;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.25s, background-color 0.25s;
}

.range {
  position: relative;
}
.range input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #f1f4fe;
  border-radius: 3px;
  background-image: linear-gradient(#004d71, #004d71);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}
.range input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  border-radius: 24px;
  background-color: #004d71;
  border: solid #fff 6px;
  cursor: ew-resize;
  box-shadow: 0 2px 14px -7px rgba(51, 62, 72, 0.6);
}
.range input[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.range label {
  position: absolute;
  width: max-content;
  top: -22px;
  left: 0;
  transform: translateX(-50%);
}
.range.inverted-track input[type=range] {
  background: #004d71;
  background-image: linear-gradient(#f1f4fe, #f1f4fe);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}
.range.label-visible {
  margin-top: 28px;
}

.input-select {
  position: relative;
  width: 100%;
}
.input-select.open .input-select-chevron {
  transform: rotateX(180deg);
}
.input-select.open .input-select-list {
  display: flex;
}
.input-select-current {
  padding: 10px;
  background-color: #fff;
  border: solid #36b9da 1px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.input-select-current:hover, .input-select-current:focus {
  transition: background-color 0.25s;
  background-color: #f1f8fb;
}
.input-select-current-value {
  line-height: 1.2;
}
.input-select-chevron {
  transition: transform 0.25s;
}
.input-select-list {
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 12px 0;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  overflow-y: auto;
  box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
  z-index: 1;
}
.input-select-list-item {
  width: 100%;
  padding: 8px 0;
  text-align: center;
  cursor: pointer;
}
.input-select-list-item:hover, .input-select-list-item:focus {
  background-color: #f1f8fb;
}

.input-text {
  color: #333e48;
  background-color: transparent;
  border: 0;
  border-bottom: solid #778998 1px;
  padding: 4px 0;
  width: 100%;
  line-height: 1;
}

.input-textarea {
  background-color: #fff;
  border: solid #dadfe4 1px;
  border-radius: 8px;
  padding: 8px;
}
.input-textarea::placeholder {
  color: #dadfe4;
}
.input-textarea:focus, .input-textarea:active {
  outline-color: #36b9da;
}

.modal {
  width: 100%;
  padding: 20px 40px;
  margin: auto;
}
@media (max-width: 1499.98px) {
  .modal {
    padding: 20px;
  }
}
.modal-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: solid #dadfe4 1px;
  margin-bottom: 10px;
}
.modal-head-content {
  flex-grow: 1;
}
.modal-head-inner {
  display: flex;
  align-items: center;
}
.modal-head-subtitle {
  margin-left: 20px;
}
.modal-close {
  margin-left: 10px;
  border: none;
}
.modal-close:before {
  transition: transform 0.25s;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: calc( 100% - 70px );
  margin-top: 70px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s;
  z-index: 100;
}
.modal-wrapper.modal-open {
  visibility: visible;
  opacity: 1;
}
.modal-wrapper.modal-open.modal-overlay {
  visibility: visible;
  opacity: 0.3;
}
.modal-container {
  position: absolute;
  top: calc(50vh - ( 70px / 2 ));
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
}
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333e48;
  opacity: 0.3;
  z-index: -1;
}

.modal-chart-zoom-body-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-chart-zoom .chart-footer {
  margin-bottom: 0;
}

.modal-config {
  width: fit-content;
}
.modal-config-head-select {
  width: 250px;
  margin-left: 15px;
}
.modal-config-loader {
  min-height: 100px;
  min-width: 200px;
  position: relative;
  opacity: 1 !important;
}
/* .modals-container {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 105;

  &.show {
    display: block;
  }
}

.modal {
  display: none;
  width: 100%;

  &.show {
    display: block;
  }
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: calc( 100% - 70px );
  background-color: $dark-gray;
  visibility: hidden;
  opacity: 0;
  transition: opacity $animation-duration;
  z-index: 100;

  &.visible {
    visibility: visible;
    opacity: 0.3;
  }
} */
.notebar {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 480px;
  height: 60px;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 10;
  transition: height 0.25s;
}
@media (max-width: 1499.98px) {
  .notebar {
    right: -480px;
    height: calc( 100vh - 70px );
    border-top-right-radius: 0;
    transition: right 0.25s;
  }
  .notebar .notebar-header-top-chevron:before {
    transform: translate(-50%, -50%) rotateZ(90deg);
  }
}
.notebar.open {
  height: calc(100vh - (70px + 18px));
}
@media (max-width: 1499.98px) {
  .notebar.open {
    right: 0;
    height: calc( 100vh - 70px );
  }
}
.notebar.open .notebar-header-top-chevron:before {
  transform: translate(-50%, -50%) rotateX(180deg);
}
@media (max-width: 1499.98px) {
  .notebar.open .notebar-header-top-chevron:before {
    transform: translate(-50%, -50%) rotateX(180deg) rotateZ(90deg);
  }
}
.notebar-note-list {
  flex-grow: 1;
  overflow-y: scroll;
}
.notebar-header-top {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 0 40px;
  cursor: pointer;
}
.notebar-header-top-subtitle {
  padding-left: 10px;
  margin-left: 10px;
  border-left: solid #dadfe4 1px;
}
.notebar-header-top-chevron {
  margin-left: auto;
}
.notebar-header-top-badge {
  width: 12px;
  height: 12px;
  background-color: #ff5000;
  border-radius: 12px;
  margin-left: 12px;
}
.notebar-header-bottom {
  display: flex;
  align-items: center;
  padding: 8px 0;
  margin: 0 40px;
  border-top: solid rgba(218, 223, 228, 0.5) 1px;
}
.notebar-header-bottom-title {
  letter-spacing: 0.56px;
}
.notebar-header-bottom-subtitle {
  padding-left: 10px;
  margin-left: 10px;
  border-left: solid #dadfe4 1px;
}
.notebar-timestamp {
  margin: 0 40px 12px 40px;
}
.notebar-timestamp-time {
  letter-spacing: 2.14px;
}
.notebar-timestamp-date {
  letter-spacing: 1px;
}
.notebar-helper {
  display: flex;
  background-color: #f1f8fb;
  padding: 10px 40px;
  /* margin-bottom: 15px; */
}
.notebar-helper.hidden {
  display: none;
}
.notebar-helper-icon {
  margin-right: 20px;
}
.notebar-helper-content {
  width: 254px;
  line-height: 1.29;
}
.notebar-helper-close {
  align-self: center;
  margin-left: auto;
  cursor: pointer;
}
.notebar-note-group {
  display: flex;
  align-items: center;
  padding: 8px 20px;
  background-color: #f1f8fb;
  border-radius: 5px;
  margin: 0 40px 12px 40px;
}
.notebar-note-group-subtitle {
  padding-left: 10px;
  margin-left: 10px;
  border-left: solid #dadfe4 1px;
}
.notebar-note {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 40px 12px 40px;
  padding: 10px 15px;
}
.notebar-note:hover .notebar-note-delete,
.notebar-note:hover .notebar-note-edit {
  visibility: visible;
  opacity: 1;
  margin-left: 15px;
  margin-right: 10px;
}
.notebar-note-content {
  margin-bottom: 20px;
}
.notebar-note-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: solid #dadfe4 1px;
  padding-top: 10px;
}
.notebar-note-owner {
  flex-grow: 1;
}
.notebar-note-delete, .notebar-note-edit {
  visibility: hidden;
  width: 0 !important;
  opacity: 0;
  margin-left: 0;
  cursor: pointer;
}
.notebar-note-timestamp {
  text-transform: capitalize;
}
.notebar-note-confirm-delete {
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  padding: 10px 20px;
  transition: opacity 0.25s;
}
.notebar-note-confirm-delete-actions {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.notebar-note-confirm-delete-actions .btn {
  padding: 4px;
  font-size: 1rem;
  width: 32px;
  height: 32px;
  border-radius: 6px;
}
.notebar-note-confirm-delete-actions .btn:last-child {
  margin-left: 10px;
}
.notebar-note.show-confirm-delete .notebar-note-confirm-delete {
  visibility: visible;
  opacity: 1;
}
.notebar-note.show-confirm-delete .notebar-note-delete,
.notebar-note.show-confirm-delete .notebar-note-edit {
  cursor: inherit;
  pointer-events: none;
}
.notebar-button-add {
  margin: 10px 40px 20px 40px;
}
.notebar-input {
  padding: 0 40px;
  visibility: hidden;
  transition: height 0.25s;
  height: 0;
  overflow: hidden;
}
.notebar-input.show {
  visibility: visible;
  height: 234px;
}
.notebar-input-buttons {
  display: flex;
  justify-content: space-between;
}
.notebar-input-buttons .btn {
  width: 193px;
}
.notebar-input-field {
  width: 100%;
  height: 133px;
  margin-bottom: 15px;
  resize: none;
}
.notebar-alert-box {
  margin: 0 40px;
  position: relative;
}
.notebar-alert-box .alert {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}
.notebar-tablet-open-button {
  display: none;
  position: fixed;
  bottom: 16px;
  right: 32px;
  z-index: 9;
}
@media (max-width: 1499.98px) {
  .notebar-tablet-open-button {
    display: block;
  }
}

.tab {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  position: relative;
  z-index: 1;
}
.tab-item {
  width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5c6670;
  transition: color 0.25s;
  position: relative;
  z-index: 4;
  cursor: pointer;
}
@media (max-width: 1499.98px) {
  .tab-item {
    width: 90px;
  }
}
.tab-item:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 10px;
  background-color: #dadfe4;
  z-index: 2;
}
.tab-item:hover {
  color: #36b9da;
}
.tab-item.active {
  color: #ff5000;
}
.tab-item.active + .tab-item:before {
  background-color: transparent;
}
.tab-item.active:before {
  display: none;
}
.tab-item.active:hover {
  color: #e74900;
}

.table-bde-head-row, .table-bde-body-row {
  display: flex;
  justify-content: space-between;
}
.table-bde:not(.column-spread) .table-bde-head-cell.cell-name,
.table-bde:not(.column-spread) .table-bde-body-cell.cell-name {
  width: 161px;
}
.table-bde:not(.column-spread) .table-bde-head-cell-group:not(:last-child),
.table-bde:not(.column-spread) .table-bde-body-cell-group:not(:last-child) {
  margin-bottom: 10px;
}
.table-bde:not(.column-spread) .table-bde-head-subcell,
.table-bde:not(.column-spread) .table-bde-body-subcell {
  display: inline-block;
  margin-left: 12px;
  text-align: right;
}
.table-bde:not(.column-spread) .table-bde-head-subcell.subcell-type,
.table-bde:not(.column-spread) .table-bde-body-subcell.subcell-type {
  width: 140px;
}
.table-bde:not(.column-spread) .table-bde-head-subcell.subcell-start-timestamp,
.table-bde:not(.column-spread) .table-bde-body-subcell.subcell-start-timestamp {
  width: 74px;
}
.table-bde:not(.column-spread) .table-bde-head-subcell.subcell-end-timestamp,
.table-bde:not(.column-spread) .table-bde-body-subcell.subcell-end-timestamp {
  width: 74px;
}
.table-bde:not(.column-spread) .table-bde-head-subcell.subcell-start-value,
.table-bde:not(.column-spread) .table-bde-body-subcell.subcell-start-value {
  width: 88px;
}
.table-bde:not(.column-spread) .table-bde-head-subcell.subcell-end-value,
.table-bde:not(.column-spread) .table-bde-body-subcell.subcell-end-value {
  width: 74px;
}
.table-bde.column-spread .table-body-cell,
.table-bde.column-spread .table-bde-body-row {
  padding: 0;
}
.table-bde.column-spread .cell-data {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.table-bde.column-spread .cell-data .table-bde-head-subcell,
.table-bde.column-spread .cell-data .table-bde-body-subcell {
  width: 100px;
}
.table-bde.column-spread .table-bde-body-cell.cell-data {
  flex-direction: column;
}
.table-bde.column-spread .table-bde-body-cell-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 40px;
}
@media (max-width: 1499.98px) {
  .table-bde.column-spread .table-bde-body-cell-group {
    padding: 10px 20px;
  }
}
.table-bde.column-spread .table-bde-body-cell-group:nth-child(even), .table-bde.column-spread .table-bde-body-cell-group:nth-child(even):before {
  background-color: #f7f8ff;
}
.table-bde.column-spread .table-bde-head-subcell.subcell-type,
.table-bde.column-spread .table-bde-body-subcell.subcell-type {
  width: 200px;
}

.table-mib-head-row, .table-mib-body-row {
  display: flex;
  justify-content: space-between;
}
.table-mib:not(.column-spread) .table-mib-head-cell.cell-name,
.table-mib:not(.column-spread) .table-mib-body-cell.cell-name {
  width: 161px;
}
.table-mib:not(.column-spread) .table-mib-head-cell-group:not(:last-child),
.table-mib:not(.column-spread) .table-mib-body-cell-group:not(:last-child) {
  margin-bottom: 10px;
}
.table-mib:not(.column-spread) .table-mib-head-subcell,
.table-mib:not(.column-spread) .table-mib-body-subcell {
  display: inline-block;
  margin-left: 12px;
  text-align: right;
}
.table-mib:not(.column-spread) .table-mib-head-subcell.subcell-start-timestamp,
.table-mib:not(.column-spread) .table-mib-body-subcell.subcell-start-timestamp {
  width: 120px;
}
.table-mib:not(.column-spread) .table-mib-head-subcell.subcell-end-timestamp,
.table-mib:not(.column-spread) .table-mib-body-subcell.subcell-end-timestamp {
  width: 120px;
}
.table-mib.column-spread .table-body-cell,
.table-mib.column-spread .table-mib-body-row {
  padding: 0;
}
.table-mib.column-spread .cell-data {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.table-mib.column-spread .cell-data .table-mib-head-subcell,
.table-mib.column-spread .cell-data .table-mib-body-subcell {
  width: 100px;
}
.table-mib.column-spread .table-mib-body-cell.cell-data {
  flex-direction: column;
}
.table-mib.column-spread .table-mib-body-cell-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 40px;
}
.table-mib.column-spread .table-mib-body-cell-group:nth-child(even), .table-mib.column-spread .table-mib-body-cell-group:nth-child(even):before {
  background-color: #f7f8ff;
}
.table-mib.column-spread .table-mib-head-subcell.subcell-type,
.table-mib.column-spread .table-mib-body-subcell.subcell-type {
  width: 200px;
}

.table-preview-head-cell, .table-preview-body-cell {
  width: 58px;
}
.table-preview-head-cell.cell-name, .table-preview-body-cell.cell-name {
  width: 185px;
}
.table-preview-head-cell.cell-icon, .table-preview-body-cell.cell-icon {
  display: flex;
  align-items: center;
  margin-right: 5px;
  padding: 0;
}
.table-preview-head-cell.cell-icon-mib, .table-preview-body-cell.cell-icon-mib {
  width: 18px;
}
.table-preview-head-cell.cell-icon-bde, .table-preview-body-cell.cell-icon-bde {
  width: 21px;
}
.table-preview-head-cell.cell-icon-note, .table-preview-body-cell.cell-icon-note {
  width: 10px;
  margin-right: auto;
}
.table-preview-head-cell.cell-icon-link, .table-preview-body-cell.cell-icon-link {
  width: 12px;
  margin-right: 0;
  margin-left: 10px;
}
.table-preview-head-cell.cell-icon-alarm, .table-preview-body-cell.cell-icon-alarm {
  width: 16px;
}
.table-preview-head-cell.cell-icon-alarm i, .table-preview-body-cell.cell-icon-alarm i {
  width: 16px;
  height: 16px;
  border-radius: 3px;
}
.table-preview-head-cell.cell-icon-alarm i.blink-animation:after, .table-preview-body-cell.cell-icon-alarm i.blink-animation:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  animation-name: thresholdBlink;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: 20;
}
@keyframes thresholdBlink {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: transparent;
  }
}
.table-preview-head-cell.cell-data, .table-preview-body-cell.cell-data {
  text-align: right;
  margin-left: 10px;
}
.table-preview-icon-note {
  width: 10px;
  height: 10px;
  background-color: #ff5000;
  border-radius: 50%;
}
.table-preview-icon-text {
  width: min-content;
  color: #5c6670;
  font-size: 10px;
  font-weight: bold;
  display: inline-flex;
}
.table-preview-icon-text.wave-animation span {
  animation: wave-text 1s ease-in-out infinite;
}
.table-preview-icon-text.wave-animation span:nth-child(2) {
  animation-delay: 150ms;
}
.table-preview-icon-text.wave-animation span:nth-child(3) {
  animation-delay: 300ms;
}
@keyframes wave-text {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-6px);
  }
  70% {
    transform: translateY(0);
  }
}
.table-preview-icon-text.active {
  color: #ff5000;
}
.table-preview-icon-config {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: block;
  width: 20px;
  height: 10px;
}
@media (max-width: 1499.98px) {
  .table-preview-icon-config {
    left: 0px;
  }
}
.table-preview-icon-link {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.table-preview-icon-link:hover .table-preview-icon-link-label, .table-preview-icon-link:active .table-preview-icon-link-label {
  color: #ff5000;
}
.table-preview-icon-link:hover i:before, .table-preview-icon-link:active i:before {
  background-color: #ff5000;
}
.table-preview-icon-link.menu-open .table-preview-icon-link-menu {
  display: flex;
}
.table-preview-icon-link-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  bottom: -10px;
  right: 20px;
  width: max-content;
  max-width: 300px;
  height: fit-content;
  max-height: 95px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  padding: 12px 15px;
}
.table-preview-icon-link-menu a {
  display: inline-block;
}
.table-preview-icon-link-menu a:not(:last-child) {
  margin-bottom: 5px;
}
.table-preview-icon-link-menu a:hover, .table-preview-icon-link-menu a:active {
  text-decoration: underline;
}
.table-preview-trend {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
}
.table-preview-trend-up, .table-preview-trend-down {
  display: block;
  width: 10px;
  height: 5px;
}
.table-preview-trend-up {
  background-color: #02d57c;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.table-preview-trend-down {
  background-color: #ff516d;
  clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
}
.table-preview-body-row:not(:hover) .table-preview-icon-config {
  opacity: 0;
  pointer-events: none;
}

.table-report {
  width: 100%;
}
.table-report-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table-report-body-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-right: 2px;
}
.table-report-body-row:last-child {
  margin-right: 0;
}
.table-report-body-cell {
  width: 100%;
  min-width: 34px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #333e48;
  font-weight: bold;
  background-color: #fff;
  box-shadow: 2px 2px 3px 0 rgba(110, 110, 110, 0.15);
  border: solid #f7f8ff 0.5px;
  border-radius: 4px;
  margin: 1px 0;
  position: relative;
  transition: background-color 0.25s, background-image 0.25s;
  cursor: pointer;
  /* &.changed {
    @extend .typo-bold;
  } */
}
.table-report-body-cell:hover {
  background-color: #f7f8ff;
}
.table-report-body-cell.past {
  background-color: #fff !important;
  color: rgba(51, 62, 72, 0.5) !important;
  border: solid #f7f8ff 0.5px;
  box-shadow: none;
  font-weight: normal !important;
  opacity: 0.7;
  transition: opacity 0.25s;
}
.table-report-body-cell.past:before {
  background-color: #dadfe4 !important;
}
.table-report-body-cell.past.offline {
  background: linear-gradient(135deg, #fff 0%, #fff 49%, rgba(119, 137, 152, 0.3) 50%, rgba(119, 137, 152, 0.3) 51%, #eaeef1 52%, #eaeef1 100%) !important;
}
.table-report-body-cell.offline {
  background: linear-gradient(135deg, #fff 0%, #fff 49%, rgba(119, 137, 152, 0.3) 50%, rgba(119, 137, 152, 0.3) 51%, #eaeef1 52%, #eaeef1 100%);
}
.table-report-body-cell.offline:hover {
  background: linear-gradient(135deg, #f7f8ff 0%, #f7f8ff 49%, rgba(119, 137, 152, 0.3) 50%, rgba(119, 137, 152, 0.3) 51%, #eaeef1 52%, #eaeef1 100%);
}
.table-report-body-cell.positive {
  background-color: #cdedf6;
}
.table-report-body-cell.positive:hover {
  background-color: #a5daea;
}
.table-report-body-cell.positive.offline {
  background: linear-gradient(135deg, #cdedf6 0%, #cdedf6 49%, rgba(119, 137, 152, 0.3) 50%, rgba(119, 137, 152, 0.3) 51%, #a5daea 52%, #a5daea 100%);
}
.table-report-body-cell.positive.offline:hover {
  background: linear-gradient(135deg, #a5daea 0%, #a5daea 49%, rgba(119, 137, 152, 0.3) 50%, rgba(119, 137, 152, 0.3) 51%, #89c6d8 52%, #89c6d8 100%);
}
.table-report-body-cell.negative {
  background-color: #ffdbcb;
}
.table-report-body-cell.negative:hover {
  background-color: #ffc0a4;
}
.table-report-body-cell.negative.offline {
  background: linear-gradient(135deg, #ffdbcb 0%, #ffdbcb 49%, rgba(119, 137, 152, 0.3) 50%, rgba(119, 137, 152, 0.3) 51%, #ffc0a4 52%, #ffc0a4 100%);
}
.table-report-body-cell.negative.offline:hover {
  background: linear-gradient(135deg, #ffc0a4 0%, #ffc0a4 49%, rgba(119, 137, 152, 0.3) 50%, rgba(119, 137, 152, 0.3) 51%, #eeae92 52%, #eeae92 100%);
}
.table-report-body-cell.no-data {
  background-color: #eaeef1;
}
.table-report-body-cell.no-data:hover {
  background-color: #cbd3d9;
}
.table-report-body-cell.badge {
  position: relative;
}
.table-report-body-cell.badge:before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 6px;
  height: 6px;
  background-color: #ff5000;
  border-radius: 6px;
}
.table-report-body-cell.selected {
  border: solid #36b9da 1px;
  opacity: 1 !important;
}
.table-report-time {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: solid rgba(218, 223, 228, 0.5) 1px;
  margin-top: 4px;
}
.table-report-time-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  width: 100%;
  text-align: center;
  padding: 2px 0 4px;
  margin-right: 2px;
}
.table-report-time-item:last-child {
  margin-right: 0;
}
.table-report-time-item.past {
  color: rgba(51, 62, 72, 0.5);
  font-size: 1.1rem;
}
.table-report-time-item.current {
  background-color: rgba(54, 185, 218, 0.1);
  position: relative;
}
.table-report-time-item.current:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 4px;
  background-color: #36b9da;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.table-report:hover {
  /* .table-report-time-item.past {
    color: $dark-gray-alpha-5 !important;
  } */
}
.table-report:hover .table-report-body-cell.past {
  opacity: 1;
}

.table {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.table-head-row, .table-body-row {
  display: flex;
  flex-direction: row;
  padding: 0 40px;
}
@media (max-width: 1499.98px) {
  .table-head-row, .table-body-row {
    padding: 0 20px;
  }
}
.table-head {
  background-color: #eceeff;
}
.table-head-cell {
  padding: 8px 0px;
}
.table-body-row {
  position: relative;
}
.table-body-row:nth-child(even), .table-body-row:nth-child(even):before {
  background-color: #f7f8ff;
}
.table-body-row:before {
  content: "";
  position: absolute;
  top: 0;
  left: calc( 8px * -1 );
  height: 100%;
  width: calc(100% + ( 8px * 2 ));
  background-color: #fff;
  box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
  border-radius: 6px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.25s;
}
.table-body-row:hover:before {
  opacity: 1;
}
.table-body-cell {
  padding: 10px 0px;
  position: relative;
  z-index: 1;
  line-height: 1;
}

.time-selector {
  display: flex;
  flex-direction: column;
  position: relative;
}
.time-selector-container {
  display: flex;
}
.time-selector-arrow {
  width: 43px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}
.time-selector-arrow.icon-disabled {
  cursor: not-allowed;
}
.time-selector-arrow-previous {
  margin-right: -8px;
}
.time-selector-arrow-next {
  margin-left: -8px;
}
.time-selector-data {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
@media (max-width: 1499.98px) {
  .time-selector-data {
    width: 140px;
  }
}
.time-selector-data-subtitle {
  color: rgba(51, 62, 72, 0.5);
}
.time-selector-input {
  border: none;
  width: 60%;
}
.time-selector .datepicker-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.time-selector .dropdown-menu {
  transform: translate(0, 0);
  z-index: 1;
}
.time-selector .dropdown-menu .ngb-dp-navigation-select {
  justify-content: center;
}
.time-selector .dropdown-menu .ngb-dp-navigation-select > select:nth-child(1) {
  max-width: 70%;
}
.time-selector .dropdown-menu .ngb-dp-navigation-select > select:nth-child(2) {
  display: none;
}
.time-selector .dropdown-menu .ngb-dp-arrow {
  display: none;
}
.time-selector ngb-datepicker {
  left: unset !important;
  right: 0 !important;
}

[data-tooltip] {
  position: relative;
  display: inline-block;
}
[data-tooltip]:before, [data-tooltip]:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 1px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s;
  transition-delay: calc( 0.25s *3 );
}
[data-tooltip]:before {
  content: attr(data-tooltip);
  min-width: 100px;
  padding: 8px 10px;
  color: #333e48;
  text-align: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
  z-index: 3;
}
[data-tooltip]:after {
  width: 10px;
  height: 10px;
  background-color: #fff;
  transform: rotate(45deg);
  z-index: 4;
}
[data-tooltip]:hover:before, [data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
[data-tooltip].tooltip-top:before {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 10px;
}
[data-tooltip].tooltip-top:after {
  bottom: calc(100% + 6.5px);
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  clip-path: polygon(100% 100%, 100% 6%, 6% 100%);
}
[data-tooltip].tooltip-bottom:before {
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  margin-top: 10px;
}
[data-tooltip].tooltip-bottom:after {
  top: calc(100% + 6px);
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  clip-path: polygon(0% 0%, 0% 94%, 94% 0%);
}
[data-tooltip].tooltip-left:before {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  margin-right: 10px;
}
[data-tooltip].tooltip-left:after {
  top: 50%;
  right: calc(100% + 7px);
  transform: translateY(-50%) rotate(45deg);
  clip-path: polygon(100% 0%, 106% 100%, 6% 0%);
}
[data-tooltip].tooltip-right:before {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 10px;
}
[data-tooltip].tooltip-right:after {
  top: 50%;
  left: calc(100% + 6px);
  transform: translateY(-50%) rotate(45deg);
  clip-path: polygon(0% 6%, 0% 100%, 94% 100%);
}
[data-tooltip].tooltip-large:before {
  min-width: 175px;
}
[data-tooltip].tooltip-auto:before {
  min-width: fit-content;
}
[data-tooltip].tooltip-auto-max:before {
  min-width: max-content;
}
[data-tooltip].tooltip-error:before, [data-tooltip].tooltip-error:after {
  border-color: #ff516d;
}

.unit-config {
  position: relative;
}
.unit-config.card, .unit-config.auction-page-guide-list-item-example, .unit-config.tab-item.active {
  padding: 22px 34px;
}
@media (max-width: 1499.98px) {
  .unit-config.card, .unit-config.auction-page-guide-list-item-example, .unit-config.tab-item.active {
    padding: 10px 20px;
  }
}
.unit-config-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: solid #dadfe4 1px;
  margin-bottom: 16px;
}
.unit-config-header-right {
  display: flex;
  align-items: center;
}
.unit-config-body {
  display: flex;
}
.unit-config-body-left, .unit-config-body-right {
  width: 50%;
}
.unit-config-body-left {
  width: 250px;
  margin-right: 50px;
}
.unit-config-body-right {
  width: 320px;
}
.unit-config-block-title {
  display: block;
  margin-bottom: 18px;
}
.unit-config-block-general {
  margin-bottom: 30px;
}
.unit-config-close {
  margin-left: 12px;
}
.unit-config-range-container {
  display: flex;
  align-items: flex-end;
  margin-top: 16px;
}
.unit-config-range-container app-input-range {
  flex-grow: 1;
}
.unit-config-range-icon {
  margin-right: 10px;
}
.unit-config-input-wrapper {
  width: 100%;
}
.unit-config-newlink-input {
  display: flex;
  margin-bottom: 15px;
}
.unit-config-newlink-icon {
  margin-left: 10px;
}
.unit-config-link-list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100px;
}
.unit-config-link-list-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.unit-config-link-list-item-view {
  display: flex;
  align-items: center;
}
.unit-config-link-list-item-view-label {
  flex-grow: 1;
}
.unit-config-link-list-item-view-icon {
  margin-left: 10px;
}
.unit-config-link-list-item-edit {
  display: flex;
  align-items: center;
}
.unit-config-link-list-item-edit-icon {
  margin-left: 10px;
}
.unit-config .custom-checkbox {
  width: fit-content;
}
.unit-config-alert-box {
  position: absolute;
  top: -140px;
  left: 0;
  width: 100%;
}

.basic-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
}
.basic-page main {
  position: relative;
  margin-top: 70px;
  margin-bottom: 90px;
  padding-top: 18px;
  flex-grow: 1;
}
.basic-page-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.basic-page-title-icon {
  margin-right: 20px;
}
.basic-page-subtitle {
  margin-bottom: 10px;
}
.basic-page-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc( 100vh - 70px );
  margin-top: 70px;
  background-color: #f7f8ff;
  /* background: linear-gradient(45deg, $ice-gray, $white); */
  background-color: #eaeef1;
  z-index: -1;
}
.basic-page-background-logo {
  width: 462px;
  height: 154px;
  margin-top: 30px;
  opacity: 0.05;
}

.login-page .card-login {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-page .card-login .btn {
  margin-top: 15px;
}

.auction-page {
  position: relative;
}
.auction-page-content {
  width: 100%;
}
.auction-page-content.notebar-opened {
  width: calc( 100% - 480px - 30px );
}
@media (max-width: 1499.98px) {
  .auction-page-content.notebar-opened {
    width: 100%;
  }
}
.auction-page .dropdown {
  margin-bottom: 32px;
}
.auction-page .card-table-preview {
  margin-bottom: 15px;
}
.auction-page .card-table-preview .cell-name {
  width: 200px;
}
.auction-page .card-table-preview .cell-data {
  margin-left: 15px;
  width: 60px;
}
@media (min-width: 1920px) {
  .auction-page .card-table-preview .cell-bde, .auction-page .card-table-preview .cell-pv, .auction-page .card-table-preview .cell-pvm, .auction-page .card-table-preview .cell-power, .auction-page .card-table-preview .cell-delta, .auction-page .card-table-preview .cell-powermin, .auction-page .card-table-preview .cell-powermax {
    width: 10%;
  }
}
.auction-page .card-table-report {
  flex-grow: 1;
  margin-bottom: 5px;
}
.auction-page .card-table-report-unavailable {
  padding: 10px 40px;
  display: flex;
  align-items: center;
}
.auction-page .card-table-report-unavailable-subtitle {
  margin-left: 20px;
}
.auction-page .accordion-bde .accordion-head {
  justify-content: center;
  padding: 5px 0;
}
.auction-page .accordion-bde .accordion-head-content {
  flex-grow: unset;
}
.auction-page .accordion-bde .accordion-chevron {
  margin-left: 5px;
}
.auction-page .accordion-bde .accordion-chevron:before {
  background-color: #36b9da;
}
.auction-page .accordion-bde .accordion-chevron:hover:before {
  background-color: #2487a0 !important;
}
.auction-page-filters {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  top: 70px;
  z-index: 8;
}
.auction-page-filters.sticky {
  position: sticky;
  background-color: #f7f8ff;
  margin: 0 -15px;
  padding: 12px 15px 12px 15px;
  border: solid 1px #e3e3e3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
}
.auction-page-guide {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.auction-page-guide-list {
  display: flex;
  align-items: center;
}
.auction-page-guide-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
@media (max-width: 1499.98px) {
  .auction-page-guide-list-item {
    margin-left: 15px;
  }
  .auction-page-guide-list-item:first-child {
    margin-left: 0;
  }
}
.auction-page-guide-list-item-example {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 23px;
  padding: 0px;
  border-radius: 5px;
  margin-right: 12px;
}
@media (max-width: 1499.98px) {
  .auction-page-guide-list-item-example {
    margin-right: 8px;
  }
}
.auction-page-guide-list-item-example.positive {
  background-color: #cdedf6;
}
.auction-page-guide-list-item-example.negative {
  background-color: #ffdbcb;
}
.auction-page-guide-list-item-example.no-data {
  background-color: #eaeef1;
}
.auction-page-guide-list-item-example.past {
  color: rgba(51, 62, 72, 0.5);
  box-shadow: none;
  border: solid 0.5px #dadfe4;
}
.auction-page-guide-list-item-example.mib {
  background: linear-gradient(135deg, #fff 0%, #fff 49%, rgba(119, 137, 152, 0.3) 50%, rgba(119, 137, 152, 0.3) 51%, #eaeef1 52%, #eaeef1 100%);
}
@media (max-width: 1499.98px) {
  .auction-page-guide-title {
    display: none;
  }
}
.auction-page .card-table-report-container {
  display: flex;
}
@media (max-width: 1499.98px) {
  .auction-page .card-table-report-container {
    display: block;
  }
}
.auction-page .card-table-report-container .card-total-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  padding: 10px 0;
  margin-left: 20px;
  margin-bottom: 5px;
}
@media (max-width: 1499.98px) {
  .auction-page .card-table-report-container .card-total-data {
    display: none;
  }
}
.auction-page .card-table-report-container .card-total-data-fieldgroup {
  display: flex;
}
.auction-page .card-table-report-container .card-total-data-fieldgroup.vertical {
  width: 100%;
  flex-direction: column;
  align-items: center;
}
.auction-page .card-table-report-container .card-total-data-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auction-page .card-table-report-container .card-total-data-value:not(.extend) {
  margin-left: 4px;
}
.auction-page .card-table-report-container .card-total-data .separator {
  display: inline-block;
  width: 100%;
  border-bottom: 1px rgba(218, 223, 228, 0.5) solid;
  text-align: center;
}
.auction-page .card-table-report-container .card-total-data .extend {
  flex-grow: 1;
}
.auction-page .card-table-report-container .card-total-data .center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.auction-page .chart-zoom {
  margin-left: auto;
}
.auction-page.view-all .accordion-group > .accordion-head {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.auction-page.view-all .accordion-group-ven > .accordion-head {
  background-image: linear-gradient(to right, antiquewhite 6%, #fff 75%);
}
.auction-page.view-all .accordion-group-ult > .accordion-head {
  background-image: linear-gradient(to right, #e2f1dd 6%, #fff 75%);
}
.auction-page.view-all .accordion-group-pus > .accordion-head {
  background-image: linear-gradient(to right, #F4F0BF 6%, #fff 75%);
}
.auction-page.view-all .accordion-group-san > .accordion-head {
  background-image: linear-gradient(to right, #f7f8ff 6%, #fff 75%);
}
.auction-page.view-all .accordion-group-isa > .accordion-head {
  background-image: linear-gradient(to right, #cdedf6 6%, #fff 75%);
}

@media (min-width: 1200px) {
  body:not(.notebar-visible) .auction-page .card-table-preview .cell-name {
    width: 250px;
  }
  body:not(.notebar-visible) .auction-page .card-table-preview .cell-icon {
    width: 32px;
  }
  body:not(.notebar-visible) .auction-page .card-table-preview .cell-bde, body:not(.notebar-visible) .auction-page .card-table-preview .cell-pv, body:not(.notebar-visible) .auction-page .card-table-preview .cell-pvm, body:not(.notebar-visible) .auction-page .card-table-preview .cell-power, body:not(.notebar-visible) .auction-page .card-table-preview .cell-delta, body:not(.notebar-visible) .auction-page .card-table-preview .cell-powermin, body:not(.notebar-visible) .auction-page .card-table-preview .cell-powermax {
    width: 110px;
  }
}

.dashboard-tabs {
  width: fit-content;
  margin: 0 auto 15px;
}
.dashboard .tileset {
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  column-gap: 30px;
  will-change: transform;
}
@media (max-width: 1499.98px) {
  .dashboard .tileset {
    -moz-column-count: unset;
    -webkit-column-count: unset;
    column-count: unset;
    column-gap: unset;
  }
}
.dashboard .tileset-tile {
  break-inside: avoid;
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}
.dashboard .tileset-tile-header {
  margin: 10px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 1499.98px) {
  .dashboard .tileset-tile-header {
    margin: 10px 20px;
  }
}
.dashboard .tileset-tile-name {
  flex-grow: 1;
}
.dashboard .tileset-tile-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard .tileset-tile-link-icon {
  width: 34px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}
.dashboard .tileset-tile-link-external {
  margin-right: 24px;
}
.dashboard .table-preview th:first-child,
.dashboard .table-preview td:first-child {
  width: 154px;
}
@media (min-width: 1920px) {
  .dashboard .table-preview .cell-bde, .dashboard .table-preview .cell-pv, .dashboard .table-preview .cell-pvm, .dashboard .table-preview .cell-power, .dashboard .table-preview .cell-delta, .dashboard .table-preview .cell-powermin, .dashboard .table-preview .cell-powermax {
    width: 10%;
  }
}
.dashboard .alert {
  margin-bottom: 10px;
}

.high-contrast .typo-body-2, .high-contrast .alert {
  font-size: 1.8rem;
  font-style: normal;
  font-stretch: normal;
  font-kerning: normal;
  font-weight: bold;
}
.high-contrast .typo-body-3 {
  font-size: 1.5rem;
}
.high-contrast .typo-textlink, .high-contrast .typo-textlink:active, .high-contrast .typo-textlink:link, .high-contrast .typo-textlink:visited {
  color: #0095bb;
}
.high-contrast .table-head {
  background-color: #b3c1e1;
}
.high-contrast .table-body-row:nth-child(even), .high-contrast .table-body-row:nth-child(even):before {
  background-color: #d6ddef;
}
.high-contrast .table-body-cell {
  padding: 9px 0 5px;
}
.high-contrast .table-bde:not(.column-spread) .table-bde-head-cell.cell-name, .high-contrast .table-bde:not(.column-spread) .table-bde-body-cell.cell-name {
  width: 168px;
}
.high-contrast .table-bde:not(.column-spread) .table-bde-head-subcell.subcell-type, .high-contrast .table-bde:not(.column-spread) .table-bde-body-subcell.subcell-type {
  width: 184px;
}
.high-contrast .table-bde:not(.column-spread) .table-bde-head-subcell.subcell-start-value, .high-contrast .table-bde:not(.column-spread) .table-bde-body-subcell.subcell-start-value {
  width: 106px;
}
.high-contrast .table-bde:not(.column-spread) .table-bde-head-subcell.subcell-end-value, .high-contrast .table-bde:not(.column-spread) .table-bde-body-subcell.subcell-end-value {
  width: 94px;
}
.high-contrast .table-bde:not(.column-spread) .table-bde-head-subcell.subcell-start-timestamp, .high-contrast .table-bde:not(.column-spread) .table-bde-body-subcell.subcell-start-timestamp {
  width: 100px;
}
.high-contrast .table-bde:not(.column-spread) .table-bde-head-subcell.subcell-end-timestamp, .high-contrast .table-bde:not(.column-spread) .table-bde-body-subcell.subcell-end-timestamp {
  width: 94px;
}
.high-contrast .table-mib:not(.column-spread) .table-mib-head-cell.cell-name, .high-contrast .table-mib:not(.column-spread) .table-mib-body-cell.cell-name {
  width: 170px;
}
.high-contrast .table-mib:not(.column-spread) .table-mib-head-subcell.subcell-start-timestamp, .high-contrast .table-mib:not(.column-spread) .table-mib-body-subcell.subcell-start-timestamp {
  width: 150px;
}
.high-contrast .table-mib:not(.column-spread) .table-mib-head-subcell.subcell-end-timestamp, .high-contrast .table-mib:not(.column-spread) .table-mib-body-subcell.subcell-end-timestamp {
  width: 150px;
}

.theme-dark {
  background-color: #000;
  color: #e3e9ee;
}
.theme-dark .typo-textlink-coral {
  color: #ff8d4e;
}
.theme-dark .typo-textlink-coral * {
  transition: color 0.25s;
  color: #ff8d4e;
}
.theme-dark .typo-gun-metal {
  color: #e3e9ee;
}
.theme-dark .btn-primary {
  background-color: #ff8d4e;
  border-color: #ff8d4e;
}
.theme-dark .btn-primary:hover, .theme-dark .btn-primary:focus {
  background-color: #e97739;
  border-color: #e97739;
}
.theme-dark .btn-secondary {
  background-color: transparent;
  border-color: #ff8d4e;
  color: #ff8d4e;
}
.theme-dark .btn.disabled.btn-primary {
  background-color: #4a5a64;
  border-color: #4a5a64;
  color: #778998;
}
.theme-dark .btn.disabled.btn-primary .btn-icon:before {
  background-color: #778998;
}
.theme-dark .input-select-current, .theme-dark .input-select-list {
  background-color: #333e48;
  border-color: #e3e9ee;
}
.theme-dark .input-select-list {
  box-shadow: 0 2px 8px 4px rgba(27, 37, 46, 0.47);
}
.theme-dark .input-select-list-item:hover, .theme-dark .input-select-list-item:focus {
  background-color: #36b9da;
}
.theme-dark .input-text {
  color: #e3e9ee;
}
.theme-dark .input-text::placeholder {
  color: #778998;
}
.theme-dark .input-textarea {
  background-color: #333e48;
  border-color: #778998;
  color: #e3e9ee;
}
.theme-dark .basic-page-background {
  background-color: #333e48;
  background-image: linear-gradient(236deg, #171c20, #333e48);
}
.theme-dark .header {
  background-color: #333e48;
  color: #fff;
}
@media (max-width: 1199.98px) {
  .theme-dark .header-menu {
    background-color: #333e48;
    box-shadow: 0 2px 8px 4px rgba(27, 37, 46, 0.47);
  }
}
@media (max-width: 1199.98px) {
  .theme-dark .header-language {
    border-top-color: #778998;
  }
}
.theme-dark .header-language-item.active {
  color: #fff;
}
.theme-dark .header-clock {
  border-color: #4f606f;
  color: #e3e9ee;
}
.theme-dark .header-user-menu .icon-black:before {
  background-color: #fff;
}
.theme-dark .header-user-menu-item-name {
  border-color: rgba(218, 223, 228, 0.5);
}
.theme-dark .alert {
  color: #fff;
}
.theme-dark .alert-theme-info {
  background-color: #36b9da;
  border-color: #eafbff;
}
.theme-dark .alert-theme-info .alert-checkbox .checkbox-checkmark {
  border-color: #333e48;
}
.theme-dark .alert-theme-error {
  background-color: #ff516d;
  border-color: #ffebee;
}
.theme-dark .alert-theme-success {
  background-color: #02d57c;
  border-color: #ecfff7;
}
.theme-dark .alert .icon-gun-metal:before {
  background-color: #fff;
}
.theme-dark .card, .theme-dark .tab-item.active, .theme-dark .auction-page-guide-list-item-example {
  background-color: #333e48;
  box-shadow: 0 2px 8px 4px rgba(27, 37, 46, 0.47);
}
.theme-dark .card-border {
  border-color: #333e48;
}
.theme-dark .tab-item {
  color: #e3e9ee;
}
.theme-dark .tab-item:hover {
  color: #fff;
}
.theme-dark .tab-item.active {
  color: #ff8d4e;
}
.theme-dark .accordion-separator {
  background-color: #4f606f;
}
.theme-dark .table-preview-head,
.theme-dark .table-bde-head,
.theme-dark .table-mib-head {
  background-color: #1b2127;
}
.theme-dark .table-preview-body-row:nth-child(even), .theme-dark .table-preview-body-row:nth-child(even):before,
.theme-dark .table-bde-body-row:nth-child(even),
.theme-dark .table-bde-body-row:nth-child(even):before,
.theme-dark .table-mib-body-row:nth-child(even),
.theme-dark .table-mib-body-row:nth-child(even):before {
  background-color: #4f606f;
  border: dashed #333e48 1px;
  box-sizing: border-box;
}
.theme-dark .table-preview-body-row:nth-child(odd), .theme-dark .table-preview-body-row:nth-child(odd):before,
.theme-dark .table-bde-body-row:nth-child(odd),
.theme-dark .table-bde-body-row:nth-child(odd):before,
.theme-dark .table-mib-body-row:nth-child(odd),
.theme-dark .table-mib-body-row:nth-child(odd):before {
  background-color: #333e48;
}
.theme-dark .table-preview-icon-text {
  color: #e3e9ee;
}
.theme-dark .table-preview-icon-text.active {
  color: #ff8d4e;
}
.theme-dark .table-bde.column-spread .table-bde-body-cell-group:nth-child(even),
.theme-dark .table-bde.column-spread .table-bde-body-cell-group:nth-child(even):before {
  background-color: #4f606f;
  border: dashed #333e48 1px;
  box-sizing: border-box;
}
.theme-dark .table-report-body-cell {
  color: #e3e9ee;
  background-color: #29323a;
  border-color: #607283;
}
.theme-dark .table-report-body-cell:hover {
  background-color: #171c20;
}
.theme-dark .table-report-body-cell.past {
  color: #e3e9ee !important;
  background-color: #404f5c !important;
  border-color: #404f5c !important;
}
.theme-dark .table-report-body-cell.past:before {
  background-color: #778998 !important;
}
.theme-dark .table-report-body-cell.past.offline {
  background: linear-gradient(135deg, #404f5c 0%, #404f5c 49%, #4f606f 50%, #4f606f 51%, #5c6670 52%, #5c6670 100%) !important;
}
.theme-dark .table-report-body-cell.offline {
  background: linear-gradient(135deg, #29323a 0%, #29323a 49%, #4f606f 50%, #4f606f 51%, #5c6670 52%, #5c6670 100%);
}
.theme-dark .table-report-body-cell.offline:hover {
  background: linear-gradient(135deg, #171c20 0%, #171c20 49%, #4f606f 50%, #4f606f 51%, #5c6670 52%, #5c6670 100%);
}
.theme-dark .table-report-body-cell.positive {
  background-color: #3ad7ff;
  border-color: #f7f8ff;
  color: #333e48;
}
.theme-dark .table-report-body-cell.positive:hover {
  background-color: #36b9da;
}
.theme-dark .table-report-body-cell.positive.offline {
  background: linear-gradient(135deg, #3ad7ff 0%, #3ad7ff 49%, #2da1be 50%, #2da1be 51%, #36b9da 52%, #36b9da 100%);
}
.theme-dark .table-report-body-cell.positive.offline:hover {
  background: linear-gradient(135deg, #36b9da 0%, #36b9da 49%, #2da1be 50%, #2da1be 51%, #0095bb 52%, #0095bb 100%);
}
.theme-dark .table-report-body-cell.negative {
  background-color: #ff8d4e;
  border-color: #f7f8ff;
  color: #333e48;
}
.theme-dark .table-report-body-cell.negative:hover {
  background-color: #ffa57d;
}
.theme-dark .table-report-body-cell.negative.offline {
  background: linear-gradient(135deg, #ff8d4e 0%, #ff8d4e 49%, #eeae92 50%, #eeae92 51%, #e97739 52%, #e97739 100%);
}
.theme-dark .table-report-body-cell.negative.offline:hover {
  background: linear-gradient(135deg, #e97739 0%, #e97739 49%, #eeae92 50%, #eeae92 51%, #ba602e 52%, #ba602e 100%);
}
.theme-dark .table-report-body-cell.no-data {
  background-color: #778998;
  color: #fff;
}
.theme-dark .table-report-body-cell.no-data:hover {
  background-color: #607283;
  color: #fff;
}
.theme-dark .table-report-body-cell.selected {
  border: solid #36b9da 1px !important;
  opacity: 1 !important;
}
.theme-dark .table-report-time {
  border-top-color: #4f606f;
}
.theme-dark .table-report-time-item.past {
  color: #778998;
}
.theme-dark .auction-page-filters.sticky {
  background-color: #4f606f;
  border-color: #4f606f;
}
.theme-dark .auction-page-guide .positive {
  background-color: #3ad7ff;
  border-color: #f7f8ff;
  color: #333e48;
}
.theme-dark .auction-page-guide .negative {
  background-color: #ff8d4e;
  border-color: #f7f8ff;
  color: #333e48;
}
.theme-dark .auction-page-guide .no-data {
  background-color: #778998;
  color: #fff;
}
.theme-dark .auction-page-guide .past {
  color: #e3e9ee;
  background-color: #404f5c;
  border-color: #404f5c;
}
.theme-dark .auction-page-guide .mib {
  background: linear-gradient(135deg, #29323a 0%, #29323a 49%, #4f606f 50%, #4f606f 51%, #5c6670 52%, #5c6670 100%);
}
.theme-dark .auction-page.view-all .accordion-group-ven > .accordion-head {
  background-image: linear-gradient(to right, antiquewhite 6%, #333e48 75%);
}
.theme-dark .auction-page.view-all .accordion-group-ult > .accordion-head {
  background-image: linear-gradient(to right, #e2f1dd 6%, #333e48 75%);
}
.theme-dark .auction-page.view-all .accordion-group-pus > .accordion-head {
  background-image: linear-gradient(to right, #F4F0BF 6%, #333e48 75%);
}
.theme-dark .auction-page.view-all .accordion-group-san > .accordion-head {
  background-image: linear-gradient(to right, #f7f8ff 6%, #333e48 75%);
}
.theme-dark .auction-page.view-all .accordion-group-isa > .accordion-head {
  background-image: linear-gradient(to right, #cdedf6 6%, #333e48 75%);
}
.theme-dark .auction-page.view-all .accordion-group .accordion-head {
  color: #333e48;
}
.theme-dark .time-selector-data-subtitle {
  color: #e3e9ee;
  opacity: 0.5;
}
.theme-dark .notebar-helper {
  background-color: #4a5a64;
}
.theme-dark .notebar-note-footer {
  border-top-color: rgba(218, 223, 228, 0.5);
}
.theme-dark .notebar-note-timestamp {
  color: #dadfe4;
}
.theme-dark .notebar-note-group {
  background-color: #36b9da;
  color: #fff;
}
.theme-dark .notebar-note-group-subtitle {
  border-left-color: #333e48;
  color: #fff;
}
.theme-dark .icon-box {
  background-color: #333e48;
  box-shadow: 0 2px 8px 4px rgba(27, 37, 46, 0.47);
  border-color: #333e48;
}
.theme-dark .icon-box-no-shadow {
  box-shadow: none;
}
.theme-dark .icon-box-sky {
  background-color: #36b9da;
}
.theme-dark .icon-box-red {
  background-color: #e30039;
}
.theme-dark .icon-box-coral {
  background-color: #ff5000;
}
.theme-dark .icon-box-green {
  background-color: #02d57c;
}
.theme-dark .icon-sky:before {
  background-color: #fff;
}
.theme-dark .icon-coral:before, .theme-dark .btn-secondary i.btn-icon:before, .btn-secondary .theme-dark i.btn-icon:before, .theme-dark .header-user:hover .header-user-chevron:before, .header-user:hover .theme-dark .header-user-chevron:before {
  background-color: #ff8d4e;
}
.theme-dark .icon-dark-gray:before {
  background-color: #e3e9ee;
}
.theme-dark .icon-alperia:before {
  background-color: #fff;
}
.theme-dark .icon-anim:active.icon-box {
  background-color: #4f606f;
}
.theme-dark [data-tooltip]:before, .theme-dark [data-tooltip]:after {
  color: #fff;
  background-color: #333e48;
  border-color: #dadfe4;
}
.theme-dark ngb-datepicker {
  background-color: #333e48;
  border-color: rgba(218, 223, 228, 0.5) !important;
  box-shadow: 0 2px 8px 4px rgba(27, 37, 46, 0.47);
}
.theme-dark ngb-datepicker .ngb-dp-navigation-select > select:nth-child(1) {
  background-color: #333e48;
  color: #e3e9ee;
}
.theme-dark ngb-datepicker .ngb-dp-day:not(.disabled) > .text-muted {
  color: #e3e9ee !important;
}
.theme-dark .chart-legend-list-item {
  background-color: #333e48;
}
.theme-dark .chart-tooltip {
  background-color: #333e48;
  box-shadow: 0 2px 8px 4px rgba(27, 37, 46, 0.47);
}

.toast-error {
  background-image: url("../assets/imgs/warning-red.svg") !important;
  background-color: #ffffff !important;
}
.toast-error * {
  color: #000000;
}

.toast-success {
  background-image: url("../assets/imgs/warning-green.svg") !important;
  background-color: #ffffff !important;
}
.toast-success * {
  color: #000000;
}

.toast-info {
  background-color: #ffffff !important;
  background-image: none;
}
.toast-info * {
  color: #000000;
}
.toast-info:before {
  content: "MIB";
  position: absolute;
  left: 14px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: coral;
  font-size: 14px;
}

.toast-warning {
  background-color: #ffffff !important;
  background-image: none;
}
.toast-warning * {
  color: #000000;
}
.toast-warning:before {
  content: "BDE";
  position: absolute;
  left: 12px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: coral;
  font-size: 14px;
}

.toast {
  width: 350px !important;
}

.toast-success *,
.toast-error *,
.toast-warning *,
.toast-info * {
  font-size: 13px;
}

.theme-dark .toast-success,
.theme-dark .toast-error,
.theme-dark .toast-warning,
.theme-dark .toast-info {
  background-color: #4f606f !important;
  box-shadow: 0 0 12px #000000 !important;
}
.theme-dark .toast-success *,
.theme-dark .toast-error *,
.theme-dark .toast-warning *,
.theme-dark .toast-info * {
  color: #ffffff !important;
}

.high-contrast .toast-success *,
.high-contrast .toast-error *,
.high-contrast .toast-warning *,
.high-contrast .toast-info * {
  font-size: 16px !important;
}