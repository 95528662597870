.basic-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;

  main {
    position: relative;
    margin-top: $header-height;
    margin-bottom: 90px;
    padding-top: $page-top-spacing;
    flex-grow: 1;
  }

  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &-icon {
      margin-right: 20px;
    }
  }

  &-subtitle {
    margin-bottom: 10px;
  }

  &-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc( 100vh - #{$header-height} );
    margin-top: $header-height;
    background-color: $ice-gray;
    /* background: linear-gradient(45deg, $ice-gray, $white); */
    background-color: $light-dust;
    z-index: -1;

    &-logo {
      width: 462px;
      height: 154px;
      margin-top: 30px;
      opacity: 0.05;
    }
  }
}