.time-selector {
  $element-height: 40px;
  $arrows-offset: -8px;

  display: flex;
  flex-direction: column;
  position: relative;

  &-container {
    display: flex;
  }

  &-arrow {
    width: 43px;
    height: $element-height;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    cursor: pointer;

    &.icon-disabled {
      cursor: not-allowed;
    }

    &-previous {
      margin-right: $arrows-offset;
    }
    
    &-next {
      margin-left: $arrows-offset;
    }
  }

  &-data {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: $element-height;
    width: 190px;
    z-index: 1;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      width: 140px;
    }

    &-subtitle {
      color: $dark-gray-alpha-5;
    }
  }

  &-input {
    border: none;
    width: 60%;
  }

  //DATEPICKER
  .datepicker-input {
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .dropdown-menu {
    transform: translate(0, 0);
    z-index: 1;

    .ngb-dp-navigation-select {
      justify-content: center;
    }

    .ngb-dp-navigation-select>select:nth-child(1) {
      max-width: 70%;
    }

    .ngb-dp-navigation-select>select:nth-child(2) {
      display: none;
    }

    .ngb-dp-arrow {
      display: none;
    }
  }

  ngb-datepicker {
    left: unset !important;
    right: 0 !important;
  }
}