.theme-dark {
  background-color: $black;
  color: $pale-gray;

  .typo {
    &-textlink-coral {
      color: $light-coral;

      * {
        transition: color $animation-duration;
        color: $light-coral;
      }
    }

    &-gun-metal {
      color: $pale-gray;
    }
  }

  .btn {
    &-primary {
      background-color: $light-coral;
      border-color: $light-coral;

      &:hover,
      &:focus {
        background-color: $dusty-orange;
        border-color: $dusty-orange;
      }
    }

    &-secondary {
      background-color: transparent;
      border-color: $light-coral;
      color: $light-coral;
    }

    &.disabled {
      &.btn-primary {
        background-color: $dusty-concrete;
        border-color: $dusty-concrete;
        color: $wolf-gray;

        .btn-icon:before {
          background-color: $wolf-gray;
        }
      }
    }
  }

  .input-select {
    &-current,
    &-list {
      background-color: $dark-gray;
      border-color: $pale-gray;
    }

    &-list {
      box-shadow: $card-shadow-dark;

      &-item {
        &:hover,
        &:focus {
          background-color: $sky;
        }
      }
    }
  }

  .input-text {
    color: $pale-gray;

    &::placeholder {
      color: $wolf-gray;
    }
  }

  .input-textarea {
    background-color: $dark-gray;
    border-color: $wolf-gray;
    color: $pale-gray;
  }

  .basic-page-background {
    background-color: $dark-gray;
    background-image: linear-gradient(236deg, $dark-gray-3, $dark-gray);
  }

  .header {
    background-color: $dark-gray;
    color: $white;

    &-menu {
      @include media-breakpoint-down(md) {
        background-color: $dark-gray;
        box-shadow: $card-shadow-dark;
      }
    }

    &-language {
      @include media-breakpoint-down(md) {
        border-top-color: $wolf-gray;
      }

      &-item.active {
        color: $white;
      }
    }

    &-clock {
      border-color: $dark-concrete;
      color: $pale-gray;
    }

    &-user-menu {
      .icon-black:before {
        background-color: $white;
      }

      &-item-name {
        border-color: $dust-alpha-5;
      }
    }
  }

  .alert {
    color: $white;

    &-theme {
      &-info {
        background-color: $sky;
        border-color: $faded-sky-4;

        .alert-checkbox .checkbox-checkmark {
          border-color: $dark-gray;
        }
      }

      &-error {
        background-color: $error;
        border-color: $light-error;
      }
  
      &-success {
        background-color: $success;
        border-color: $light-success-2;
      }
    }

    .icon-gun-metal:before {
      background-color: $white;
    }
  }

  .card {
    background-color: $dark-gray;
    box-shadow: $card-shadow-dark;

    &-border {
      border-color: $dark-gray;
    }
  }

  .tab {
    &-item {
      color: $pale-gray;

      &:hover {
        color: $white;
      }

      &.active {
        color: $light-coral;
      }
    }
  }

  .accordion {
    &-separator {
      background-color: $dark-concrete;
    }
  }

  .table-preview,
  .table-bde,
  .table-mib {
    &-head {
      background-color: $dark-gray-4;
    }

    &-body-row:nth-child(even),
    &-body-row:nth-child(even):before {
      background-color: $dark-concrete;
      border: dashed $dark-gray 1px;
      box-sizing: border-box;
    }

    &-body-row:nth-child(odd),
    &-body-row:nth-child(odd):before {
      background-color: $dark-gray;
    }
  }

  .table-preview {
    &-icon {
      &-text {
        color: $pale-gray;

        &.active {
          color: $light-coral;
        }
      }
    }
  }

  .table-bde.column-spread {
    .table-bde-body-cell-group:nth-child(even),
    .table-bde-body-cell-group:nth-child(even):before {
      background-color: $dark-concrete;
      border: dashed $dark-gray 1px;
      box-sizing: border-box;
    }
  }

  .table-report {
    &-body-cell {
      color: $pale-gray;
      background-color: $dark-gray-2;
      border-color: $wolf-gray-2;

      &:hover {
        background-color: $dark-gray-3;
      }

      &.past {
        color: $pale-gray !important;
        background-color: $dark-concrete-2 !important;
        border-color: $dark-concrete-2 !important;

        &:before {
          background-color: $wolf-gray !important;
        }
      }

      &.past.offline {
        background: linear-gradient(135deg, $dark-concrete-2 0%, $dark-concrete-2 49%, $dark-concrete 50%, $dark-concrete 51%, $gun-metal 52%, $gun-metal 100%) !important;
      }

      &.offline {
        background: linear-gradient(135deg, $dark-gray-2 0%, $dark-gray-2 49%, $dark-concrete 50%, $dark-concrete 51%, $gun-metal 52%, $gun-metal 100%);
  
        &:hover {
          background: linear-gradient(135deg, $dark-gray-3 0%, $dark-gray-3 49%, $dark-concrete 50%, $dark-concrete 51%, $gun-metal 52%, $gun-metal 100%);
        }
      }
  
      &.positive {
        background-color: $sky-2;
        border-color: $ice-gray;
        color: $dark-gray;
  
        &:hover {
          background-color: $sky;
        }
      }
  
      &.positive.offline {
        background: linear-gradient(135deg, $sky-2 0%, $sky-2 49%, $dark-sky-2 50%, $dark-sky-2 51%, $sky 52%, $sky 100%);
  
        &:hover {
          background: linear-gradient(135deg, $sky 0%, $sky 49%, $dark-sky-2 50%, $dark-sky-2 51%, $sky--high_contrast 52%, $sky--high_contrast 100%);
        }
      }
  
      &.negative {
        background-color: $light-coral;
        border-color: $ice-gray;
        color: $dark-gray;
  
        &:hover {
          background-color: $faded-coral;
        }
      }
  
      &.negative.offline {
        background: linear-gradient(135deg, $light-coral 0%, $light-coral 49%, $dark-faded-coral 50%, $dark-faded-coral 51%, $dusty-orange 52%, $dusty-orange 100%);
  
        &:hover {
          background: linear-gradient(135deg, $dusty-orange 0%, $dusty-orange 49%, $dark-faded-coral 50%, $dark-faded-coral 51%, $dark-faded-coral-2 52%, $dark-faded-coral-2 100%);
        }
      }
  
      &.no-data {
        background-color: $wolf-gray;
        color: $white;
  
        &:hover {
          background-color: $wolf-gray-2;
          color: $white;
        }
      }

      &.selected {
        border: solid $sky 1px !important;
        opacity: 1 !important;
      }
    }

    &-time {
      border-top-color: $dark-concrete;

      &-item.past {
        color: $wolf-gray;
      }
    }
  }

  .auction-page {
    &-filters.sticky {
      background-color: $dark-concrete;
      border-color: $dark-concrete;
    }

    &-guide {
      .positive {
        background-color: $sky-2;
        border-color: $ice-gray;
        color: $dark-gray;
      }

      .negative {
        background-color: $light-coral;
        border-color: $ice-gray;
        color: $dark-gray;
      }
      
      .no-data {
        background-color: $wolf-gray;
        color: $white;
      }

      .past {
        color: $pale-gray;
        background-color: $dark-concrete-2;
        border-color: $dark-concrete-2;
      }

      .mib {
        background: linear-gradient(135deg, $dark-gray-2 0%, $dark-gray-2 49%, $dark-concrete 50%, $dark-concrete 51%, $gun-metal 52%, $gun-metal 100%);
      }
    }

    &.view-all {
      .accordion-group {
        &-ven>.accordion-head { background-image: linear-gradient(to right, antiquewhite 6%, $dark-gray 75%); }
        &-ult>.accordion-head { background-image: linear-gradient(to right, #e2f1dd 6%, $dark-gray 75%); }
        &-pus>.accordion-head { background-image: linear-gradient(to right, #F4F0BF 6%, $dark-gray 75%); }
        &-san>.accordion-head { background-image: linear-gradient(to right, $ice-gray 6%, $dark-gray 75%); }
        &-isa>.accordion-head { background-image: linear-gradient(to right, $faded-sky-3 6%, $dark-gray 75%); }

        .accordion-head {
          color: $dark-gray;
        }
      }
    }
  }

  .time-selector {
    &-data-subtitle {
      color: $pale-gray;
      opacity: 0.5;
    }
  }

  .notebar {
    &-helper {
      background-color: $dusty-concrete;
    }

    &-note {
      &-footer {
        border-top-color: $dust-alpha-5;
      }

      &-timestamp {
        color: $dust;
      }

      &-group {
        background-color: $sky;
        color: $white;
  
        &-subtitle {
          border-left-color: $dark-gray;
          color: $white;
        }
      }
    }
  }

  .icon {
    &-box {
      background-color: $dark-gray;
      box-shadow: $card-shadow-dark;
      border-color: $dark-gray;

      &-no-shadow {
        box-shadow: none;
      }

      &-sky {
        background-color: $sky;
      }

      &-red {
        background-color: $red;
      }

      &-coral {
        background-color: $coral;
      }

      &-green {
        background-color: $success;
      }
    }

    &-sky:before {
      background-color: $white;
    }

    &-coral:before {
      background-color: $light-coral;
    }

    &-dark-gray:before {
      background-color: $pale-gray;
    }

    &-alperia:before {
      background-color: $white;
    }

    &-anim {
      &:active {
        &.icon-box {
          background-color: $dark-concrete;
        }
      }
    }
  }

  [data-tooltip] {
    &:before,
    &:after {
      color: $white;
      background-color: $dark-gray;
      border-color: $dust;
    }
  }

  ngb-datepicker {
    background-color: $dark-gray;
    border-color: $dust-alpha-5 !important;
    box-shadow: $card-shadow-dark;
  
    .ngb-dp-navigation-select>select:nth-child(1) {
      background-color: $dark-gray;
      color: $pale-gray;
    }

    .ngb-dp-day:not(.disabled)>.text-muted {
      color: $pale-gray !important;
    }
  }

  .chart {
    &-legend-list-item {
      background-color: $dark-gray;
    }
    
    &-tooltip {
      background-color: $dark-gray;
      box-shadow: $card-shadow-dark; 
    }
  }
}