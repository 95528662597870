.range {
  $track-height: 8px;
  $track-border-radius: 3px;
  $thumb-size: 24px;
  $thumb-border-size: 6px;

  position: relative;

  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: $track-height;
    background: $pale-blue;
    border-radius: $track-border-radius;
    background-image: linear-gradient($blue, $blue);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: $thumb-size;
      width: $thumb-size;
      border-radius: $thumb-size;
      background-color: $blue;
      border: solid $white $thumb-border-size;
      cursor: ew-resize;
      box-shadow: 0 2px 14px -7px rgba(51, 62, 72, 0.6);
    }
    
    &::-webkit-slider-runnable-track  {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  label {
    position: absolute;
    width: max-content;
    top: -22px;
    left: 0;
    transform: translateX(-50%);
  }

  &.inverted-track {
    input[type=range] {
      background: $blue;
      background-image: linear-gradient($pale-blue, $pale-blue);
      background-size: 0% 100%;
      background-repeat: no-repeat;
    }
  }

  &.label-visible {
    margin-top: 28px;
  }
}