.btn {
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color $animation-duration, background-color $animation-duration, border-color $animation-duration;
  
  padding: 15px 35px;
  @extend .typo-body-1;
  @extend .typo-bold;
  @extend .typo-uppercase;
  letter-spacing: 0.89px;

  &:focus,
  &:active {
    border: solid $sky 2px !important;
    outline: none;
  }

  &:hover {
    .btn-text {
      transform: scale(1.1);
    }

    i.btn-icon:before {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  &:active {
    .btn-text {
      transform: scale(0.9);
    }

    i.btn-icon:before {
      transform: translate(-50%, -50%) scale(0.9);
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &-primary {
    background-color: $coral;
    color: $white;
    border: solid $coral 2px;

    i.btn-icon {
      @extend .icon-white;
    }

    &:hover {
      background-color: $dark-coral-2;
      border: solid $dark-coral-2 2px;
    }

    &.disabled {
      background-color: $concrete;
      border-color: $concrete;
    }
  }

  &-secondary {
    background-color: $white;
    color: $coral;
    border: solid $white 2px;
    box-shadow: $icon-box-shadow;

    i.btn-icon {
      @extend .icon-coral;
    }

    &:hover {
      color: $dark-coral-2;

      i.btn-icon:before {
        background-color: $dark-coral-2;
      }
    }

    &.disabled {
      color: $concrete;
      border: solid $concrete 2px;
      box-shadow: none;

      i.btn-icon {
        @extend .icon-concrete;
      }
    }
  }

  &-text {
    flex-grow: 1;
    transition: transform $animation-duration;
  }
}