i {
  // ICON VARIABLES
  $box-width-small:   34px;
  $box-height-small:  30px;
  $box-width-medium:  43px;
  $box-height-medium: 40px;
  $box-width-large:   45px;
  $box-height-large:  45px;

  $icon-size-small:   14px;
  $icon-size-medium:  20px;
  $icon-size-large:   28px;

  // ICON BASE
  display: inline-block;
  position: relative;
  transition: $animation-duration;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    display: inline-block;
    background-color: $dark-gray;
    transition: transform $animation-duration;
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
  }

  &.icon {
    // ICON SIZES
    &-small {
      width:  $icon-size-small;
      height: $icon-size-small;
      &:before {
        width:  $icon-size-small;
        height: $icon-size-small;
      }
    }
    &-medium {
      width:  $icon-size-medium;
      height: $icon-size-medium;
      &:before {
        width:  $icon-size-medium;
        height: $icon-size-medium;
      }
    }
    &-large {
      width:  $icon-size-large;
      height: $icon-size-large;
      &:before {
        width:  $icon-size-large;
        height: $icon-size-large;
      }
    }

    // ICON BOX
    &-box {
      background-color: $white;
      box-shadow: $icon-box-shadow;
      border: solid 0.5px $ice-gray;
      border-radius: 10px;

      &-no-shadow { box-shadow: none; }
      &-no-border { border: 0; }

      // ICON BOX SIZES
      &-small {
        width:  $box-width-small;
        height: $box-height-small;
      }
      &-medium {
        width:  $box-width-medium;
        height: $box-height-medium;
      }
      &-large {
        width:  $box-width-large;
        height: $box-height-large;
      }

      // ICON BOX COLORS
      &-sky {
        background-color: $sky-alpha-2;
        border: 0;
      }
      &-coral {
        background-color: $coral;
        border: 0;
      }
      &-red {
        background-color: $red;
      }
      &-green {
        background-color: $success;
      }
    }

    // ICON COLORS
    &-dark-gray {
      &:before {
        background-color: $dark-gray;
      }
    }
    &-white {
      &:before {
        background-color: $white;
      }
    }
    &-sky {
      &:before {
        background-color: $sky;
      }
    }
    &-coral {
      &:before {
        background-color: $coral;
      }
    }
    &-gun-metal {
      &:before {
        background-color: $gun-metal;
      }
    }
    &-concrete {
      &:before {
        background-color: $concrete;
      }
    }
    &-red {
      &:before {
        background-color: $red;
      }
    }

    // ICON INTERACTIONS
    &-anim {
      &:hover {
        transform: scale(1.1);

        // ICON INTERACTION COLORS
        &.icon {
          &-dark-gray:before {
            background-color: $coral;
          }
          &-sky:before {
            background-color: $dark-sky;
          }
          &-coral:before {
            background-color: $dark-coral;
          }
          &-gun-metal:before {
            background-color: $dark-gray;
          }
        }
      }

      &:active {
        transform: scale(0.9);

        &.icon-box {
          background-color: $ice;

          &.icon-box-coral {
            background-color: $dark-coral;
          }
        }
      }
    }

    &-clickable {
      cursor: pointer;
    }

    &-disabled {
      pointer-events: none;
      cursor: auto;

      &:before {
        background-color: $concrete !important;
      }

      &.icon-box {
        &:hover, &:active {
          background-color: $white;
        }
      }
    }

    // ICONS
    &-alperia:before { mask-image: url("./../assets/imgs/alperia.svg"); background-color: $wolf-gray; }
    &-meter:before { mask-image: url("./../assets/imgs/meter-sky.svg"); }
    &-graph:before { mask-image: url("./../assets/imgs/graph-coral.svg"); }
    &-question:before { mask-image: url("./../assets/imgs/question.svg"); }
    &-chevron-up:before { mask-image: url("./../assets/imgs/chevron-up-coral.svg"); }
    &-chevron-left:before { mask-image: url("./../assets/imgs/chevron-left-coral.svg"); }
    &-chevron-right:before { mask-image: url("./../assets/imgs/chevron-right-coral.svg"); }
    &-chevron-down:before { mask-image:url("./../assets/imgs/chevron-down-dark-gray.svg"); }
    &-location:before { mask-image: url("./../assets/imgs/location-sky.svg"); }
    &-note:before { mask-image: url("./../assets/imgs/note-sky.svg"); }
    &-pencil:before { mask-image: url("./../assets/imgs/pencil-coral.svg"); }
    &-plus:before { mask-image: url("./../assets/imgs/plus-white.svg"); }
    &-reload:before { mask-image: url("./../assets/imgs/reload-red.svg"); }
    &-zoom-in:before { mask-image: url("./../assets/imgs/zoom-in-coral.svg"); }
    &-zoom-out:before { mask-image: url("./../assets/imgs/zoom-out-coral.svg"); }
    &-delete:before { mask-image: url("./../assets/imgs/delete-coral.svg"); }
    &-cross:before { mask-image: url("./../assets/imgs/cross-coral.svg"); }
    &-exit:before { mask-image: url("./../assets/imgs/exit-black.svg"); }
    &-rocket:before { mask-image: url("./../assets/imgs/rocket-dark-gray.svg"); }
    &-checkmark:before { mask-image: url("./../assets/imgs/checkmark-coral.svg"); }
    &-burger:before { mask-image: url("./../assets/imgs/burger-coral.svg"); }
    &-info:before { mask-image: url("./../assets/imgs/info-black.svg"); }
    &-file:before { mask-image: url("./../assets/imgs/file-text-black.svg"); }
    &-contrast:before { mask-image: url("./../assets/imgs/contrast-black.svg"); }
    &-moon:before { mask-image: url("./../assets/imgs/moon-black.svg"); }
    &-sun:before { mask-image: url("./../assets/imgs/sun-black.svg"); }
    &-gear:before { mask-image: url("./../assets/imgs/gear-black.svg"); }
    &-lightning:before { mask-image: url("./../assets/imgs/lightning-black.svg"); }
    &-clock:before { mask-image: url("./../assets/imgs/clock-black.svg"); }
    &-address-book:before { mask-image: url("./../assets/imgs/address-book-black.svg"); }
    &-paperclip:before { mask-image: url("./../assets/imgs/paperclip-black.svg"); }
    &-phone-out:before { mask-image: url("./../assets/imgs/phone-out-black.svg"); }
    &-warning:before { mask-image: url("./../assets/imgs/warning-black.svg"); }
    &-bell-ringing:before { mask-image: url("./../assets/imgs/bell-ringing-black.svg"); }
    &-bell-slash:before { mask-image: url("./../assets/imgs/bell-slash-black.svg"); }
  }
}
