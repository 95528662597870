// CUSTOM RESET
button {
  background-color: inherit;
  border: initial;
  font-size: inherit;
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
  }
}

i {
  font-style: normal;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

// COMMON
html {
  font-size: 10px;
}

body {
  min-height: 100vh;
  font-family: Arial;
  color: $dark-gray;
}

.page-spacing {
  margin-left: $page-spacing;
  margin-right: $page-spacing;
}

.loader-container {
  opacity: 0.2 !important;
  transition: opacity $animation-duration;
  pointer-events: none;
}

.loader-spinner {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  width: $loader-spinner-size;
  height: $loader-spinner-size;
  background-color: transparent;
  border-style: solid;
  border-color: $sky;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  animation: loaderSpinner 1s linear infinite;
}

@keyframes loaderSpinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loader {
  &-report {
    .table-report {
      position: relative;

      &-body-row {
        @extend .loader-container;
      }

      &:after {
        @extend .loader-spinner;
      }
    }

    .chart {
      position: relative;

      .chartjs,
      .chart-footer {
        @extend .loader-container;
      }

      &:after {
        @extend .loader-spinner;
      }
    }

    .table-bde {
      position: relative;

      &-body {
        @extend .loader-container;
      }

      &:after {
        @extend .loader-spinner;
      }
    }
  }

  &-recap {
    .table-preview {
      position: relative;

      &-body {
        @extend .loader-container;
      }

      &:after {
        @extend .loader-spinner;
      }
    }
  }

  &-dashboard {
    .table-preview {
      position: relative;

      &-body {
        @extend .loader-container;
      }

      &:after {
        @extend .loader-spinner;
      }
    }
  }

  &-bde {
    .table-bde {
      position: relative;

      &-body {
        @extend .loader-container;
      }

      &:after {
        @extend .loader-spinner;
      }
    }
  }

  &-notes {
    .notebar-note-list {
      position: relative;

      .notebar-note-group,
      .notebar-note {
        @extend .loader-container;
      }

      &:after {
        @extend .loader-spinner;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

body.modal-open {
  overflow-y: hidden;
}

// CUSTOM
/* .outline-sky:focus {
  outline: solid $sky 1.5px;
} */

/* :focus {
  border: solid $sky 1.5px !important;
  border-radius: inherit;
} */