.unit-config {
  position: relative;

  &.card {
    padding: 22px 34px;

    @include media-breakpoint-down(lg) {
      padding: 10px 20px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 14px;
    border-bottom: solid $dust 1px;
    margin-bottom: 16px;

    &-right {
      display: flex;
      align-items: center;
    }
  }

  &-body {
    display: flex;

    &-left,
    &-right {
      width: 50%;
    }

    &-left {
      width: 250px;
      margin-right: 50px;
    }

    &-right {
      width: 320px;
    }
  }

  &-block {
    &-title {
      display: block;
      margin-bottom: 18px;
    }

    &-general {
      margin-bottom: 30px;
    }
  }

  &-close {
    margin-left: 12px;
  }

  &-range {
    &-container {
      display: flex;
      align-items: flex-end;
      margin-top: 16px;

      app-input-range {
        flex-grow: 1;
      }
    }

    &-icon {
      margin-right: 10px;
    }
  }

  &-input-wrapper {
    width: 100%;
  }

  &-newlink {
    &-input {
      display: flex;
      margin-bottom: 15px;
    }

    &-icon {
      margin-left: 10px;
    }
  }

  &-link-list {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100px;

    &-item {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;

      &-view {
        display: flex;
        align-items: center;

        &-label {
          flex-grow: 1;
        }
  
        &-icon {
          margin-left: 10px;
        }
      }

      &-edit {
        display: flex;
        align-items: center;

        &-icon {
          margin-left: 10px;
        }
      }
    }
  }

  .custom-checkbox {
    width: fit-content;
  }

  &-alert-box {
    position: absolute;
    top: -140px;
    left: 0;
    width: 100%;
  }
}