.typo {
  // Typo sizes
  &-heading-1 {
    font-size: 3rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
  }
  &-heading-2 {
    font-size: 2.4rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
  }
  &-heading-3 {
    font-size: 2rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
    letter-spacing: 0.5px;
  }
  &-heading-4 {
    font-size: 1.6rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
  }
  &-body-1 {
    font-size: 1.6rem;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
  }
  &-body-2 {
    font-size: 1.4rem;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
  }
  &-body-2-noquery {
    font-size: 1.4rem;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
  }
  &-body-3 {
    font-size: 1.2rem;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
  }
  &-body-3-noquery {
    font-size: 1.2rem;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
  }

  //Typo colors
  &-dark-gray {
    color: $dark-gray;
  }
  &-white {
    color: $white;
  }
  &-blue {
    color: $blue;
  }
  &-sky {
    color: $sky;
  }
  &-dark-sky {
    color: $dark-sky;
  }
  &-wolf-gray {
    color: $wolf-gray;
  }
  &-gun-metal {
    color: $gun-metal;
  }
  &-concrete {
    color: $concrete;
  }
  &-sky {
    color: $sky;
  }

  //Typo weights
  &-bold {
    font-weight: bold;
  }

  &-regular {
    font-weight: normal;
  }

  //Typo custom
  &-textlink,
  &-textlink:active,
  &-textlink:link,
  &-textlink:visited {
    color: $sky;
    transition: color $animation-duration;

    &:hover {
      text-decoration: underline;
      color: $dark-sky;
    }

    * {
      color: $sky;
    }
  }

  &-textlink-coral,
  &-textlink-coral:active,
  &-textlink-coral:link,
  &-textlink-coral:visited {
    font-size: 1.4rem;
    font-style: normal;
    font-stretch: normal;
    font-kerning: normal;
    color: $coral;
    transition: color $animation-duration;

    &:hover {
      text-decoration: underline;
      color: $dark-coral;

      * {
        color: $dark-coral;
      }
    }

    * {
      transition: color $animation-duration;
      color: $coral;
    }
  }

  //TYPO transform
  &-capitalize {
    text-transform: capitalize;
  }
  &-uppercase {
    text-transform: uppercase;
  }
  &-lowercase {
    text-transform: lowercase;
  }
}

.text-trim-1 {
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}