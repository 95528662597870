.login-page {
    .card-login {
        width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .btn {
            margin-top: 15px;
        }
    }
}