
.toast-error {
    background-image: url('../assets/imgs/warning-red.svg') !important;
    background-color: #ffffff !important;
    *{
      color: #000000;
    }
  }
  .toast-success {
    background-image: url('../assets/imgs/warning-green.svg') !important;
    background-color: #ffffff !important;
    *{
      color: #000000;
    }
  }
  .toast-info {
    background-color: #ffffff !important;
    background-image: none;
    *{
      color: #000000;
    }
  
    &:before{
      content: 'MIB';
      position: absolute;
      left: 14px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: coral;
      font-size: 14px;
    }
  }
  .toast-warning {
    background-color: #ffffff !important;
    background-image: none;
    *{
      color: #000000;
    }
  
  
    &:before{
      content: 'BDE';
      position: absolute;
      left: 12px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: coral;
      font-size: 14px;
    }
  }

  .toast{
    width: 350px !important;
  }
  
  .toast-success,
  .toast-error,
  .toast-warning,
  .toast-info{
    *{
      font-size: 13px;
    }
  }

  .theme-dark{
    .toast-success,
    .toast-error,
    .toast-warning,
    .toast-info{
      background-color: #4f606f !important;
      box-shadow: 0 0 12px #000000 !important;
      *{
        color: #ffffff !important;
      }
    }
  }
  
  .high-contrast{
    .toast-success,
    .toast-error,
    .toast-warning,
    .toast-info{
      *{
        font-size: 16px !important;
      }
    }
  }