.alert {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  z-index: 50;

  @extend .typo-body-2;
  @extend .typo-dark-gray;

  &-speed {
    &-slow {
      animation-name: fade-in;
      animation-duration: calc( #{$animation-duration} * 3);
      animation-timing-function: ease-in;
    }
  
    &-fast {
      animation-name: fade-in;
      animation-duration: $animation-duration;
      animation-timing-function: ease-in;
    }
  }

  &-icon {
    margin-right: 8px;
    width: 55px;
  }

  &-controls {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &-checkbox {
    margin: 0 22px 0 0;
    width: max-content;
  }

  &-close {
    cursor: pointer;
  }

  &-theme {
    &-info {
      border-color: $sky;
      background-color: $faded-sky-4;

      .alert-checkbox .checkbox-checkmark {
        border: solid $sky 1px;
      }
    }

    &-error {
      border-color: $error;
      background-color: $light-error;
    }

    &-success {
      border-color: $success;
      background-color: $light-success-2;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &-autoclose {
    overflow: hidden;

    &-animation {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: $black-alpha-1;
      background-image: linear-gradient(to right, transparent , #{$black-alpha-1});
      opacity: 0;
      z-index: -1;
      animation: loading linear;
    }

    @keyframes loading {
      from {
        width: 0;
        opacity: 0;
      }
      to {
        width: 100%;
        opacity: 0.5;
      }
    }
  }
}