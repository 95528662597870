.chart {
  $sample-border-size: 1px;
  $sample-size: calc( 12px - #{$sample-border-size} );

  position: relative;

  &-canvas-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 15px;
  }

  &-legend {
    display: flex;
    align-items: center;

    &-list {
      display: flex;
      align-items: center;

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        background-color: $white;
        box-shadow: $icon-box-shadow;
        border-radius: 10px;
        border: solid 0.5px $ice-gray;
        padding: 0 10px;
        margin-left: 10px;
        cursor: pointer;

        &-label {
          transition: color $animation-duration;
        }

        &-sample {
          width: $sample-size;
          height: $sample-size;
          border-style: solid;
          border-width: $sample-border-size;
          border-radius: $sample-size;
          margin-right: 6px;
          transition: background-color $animation-duration;
        }

        &:hover {
          .chart-legend-list-item-label {
            color: $sky;
          }
        }
  
        &.inactive {
          .chart-legend-list-item-sample {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  &-tooltip {
    display: block;
    position: absolute;
    top: 5px;
    width: 124px;
    height: fit-content;
    padding: 14px 10px 20px 10px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: $card-shadow;
    opacity: 0;
    transition: opacity $animation-duration, left $animation-duration;
    pointer-events: none;

    &-head {
      line-height: 1;
      padding-bottom: 8px;
      border-bottom: solid $dust 1px;
      margin-bottom: 8px;
    }

    &-body {
      display: flex;
      justify-content: space-between;
    }
  }
}