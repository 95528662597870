.accordion {
  $accordion-big-padding: 40px;
  $accordion-small-padding: 20px;

  &.open {
    & > .accordion-head .accordion-chevron:before {
      transform: translate(-50%, -50%) rotateX(180deg);
    }

    & > .accordion-body {
      display: block;
    }
  }

  &-head {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
    }
  }

  &-chevron {
    margin-left: 10px;

    &:before {
      transition: transform $animation-duration;
    }
  }

  &-body {
    display: none;

    &-inner {
      display: inline-block;
      width: 100%;
      vertical-align: bottom;

      .accordion-body-scroll {
        @include media-breakpoint-down(lg) {
          overflow-x: auto;
        }
      }
    }
  }

  &.accordion-big {
    & > .accordion-head {
      padding: 0;

      .accordion-head-content {
        flex-grow: 1;
      }

      .accordion-head-subtitle {
        margin-left: 20px;
      }

      .accordion-head-button {
        margin-left: 10px;
      }
    }

    .accordion-big-margin {
      margin-left: $accordion-big-padding;
      margin-right: $accordion-big-padding;

      @include media-breakpoint-down(lg) {
        margin-left: $accordion-small-padding;
        margin-right: $accordion-small-padding;
      }
    }

    .accordion-big-padding {
      padding-left: $accordion-big-padding;
      padding-right: $accordion-big-padding;
      @include media-breakpoint-down(lg) {
        padding-left: $accordion-small-padding;
        padding-right: $accordion-small-padding;
      }
    }
  }

  &-separator {
    width: 100%;
    height: 1px;
    background-color: $dust-alpha-5;
    border-style: none;
    border-width: 0;

    @include media-breakpoint-down(lg) {
      width: calc(100% - #{$accordion-small-padding} );
    }
  }
}