[data-tooltip] {
  position: relative;
  display: inline-block;

  &:before,
  &:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 1px;
    visibility: hidden;
    opacity: 0;
    transition: opacity $animation-duration;
    transition-delay: calc( #{$animation-duration} *3 );
  }

  &:before {
    content: attr(data-tooltip);
    min-width: 100px;
    padding: 8px 10px;
    color: $dark-gray;
    text-align: center;
    background-color: $white;
    border-radius: 5px;
    box-shadow: $card-shadow;
    z-index: 3;
  }

  &:after {
    width: 10px;
    height: 10px;
    background-color: $white;
    transform: rotate(45deg);
    z-index: 4;
  }

  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }

  &.tooltip {
    //TOOLTIP positions
    &-top {
      &:before {
        bottom: 100%;
        left: 50%;
        transform: translate(-50%);
        margin-bottom: 10px;
      }

      &:after {
        bottom: calc(100% + 6.5px);
        left: 50%;
        transform: translate(-50%) rotate(45deg);
        clip-path: polygon(100% 100%, 100% 6%, 6% 100%);
      }
    }

    &-bottom {
      &:before {
        top: 100%;
        left: 50%;
        transform: translate(-50%);
        margin-top: 10px;
      }

      &:after {
        top: calc(100% + 6px);
        left: 50%;
        transform: translate(-50%) rotate(45deg);
        clip-path: polygon(0% 0%, 0% 94%, 94% 0%);
      }
    }

    &-left {
      &:before {
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        margin-right: 10px;
      }

      &:after {
        top: 50%;
        right: calc(100% + 7px);
        transform: translateY(-50%) rotate(45deg);
        clip-path: polygon(100% 0%, 106% 100%, 6% 0%);
      }
    }

    &-right {
      &:before {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        margin-left: 10px;
      }

      &:after {
        top: 50%;
        left: calc(100% + 6px);
        transform: translateY(-50%) rotate(45deg);
        clip-path: polygon(0% 6%, 0% 100%, 94% 100%);
      }
    }

    //TOOLTIP sizes
    &-large {
      &:before {
        min-width: 175px;
      }
    }
    &-auto {
      &:before {
        min-width: fit-content;
      }
    }
    &-auto-max {
      &:before {
        min-width: max-content;
      }
    }

    //TOOLTIP themes
    &-error:before,
    &-error:after {
      border-color: $error;
    }
  }
}