$header-height: 70px;
$page-spacing: 30px;
$page-top-spacing: 18px;
$spacing-xs: 16px;
$spacing-sm: 24px;
$spacing-md: 32px;
$animation-duration: .25s;
$text-animation-duration: 1s;
$card-shadow: 0 2px 15px 0 rgba(51, 62, 72, 0.15);
$card-shadow-dark: 0 2px 8px 4px rgba(27, 37, 46, 0.47);
$card-border-radius: 10px;
$cell-shadow: 2px 2px 3px 0 rgba(110, 110, 110, 0.15);
$icon-box-shadow: 0 2px 14px -7px rgba(51, 62, 72, 0.6);
$loader-spinner-size: 25px;
$notebar-width: 480px;