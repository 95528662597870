.dashboard {
  &-tabs {
    width: fit-content;
    margin: 0 auto 15px;
  }

  .tileset {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
    column-gap: 30px;
    will-change: transform;

    @include media-breakpoint-down(lg) {
      -moz-column-count: unset;
      -webkit-column-count: unset;
      column-count: unset;
      column-gap: unset;
    }

    &-tile {
      break-inside: avoid;
      display: inline-block;
      width: 100%;
      margin-bottom: 15px;

      &-header {
        margin: 10px 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include media-breakpoint-down(lg) {
          margin: 10px 20px;
        }
      }

      &-name {
        flex-grow: 1;
      }

      &-link {
        display: flex;
        justify-content: center;
        align-items: center;

        &-icon {
          width: 34px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;  
        }

        &-external {
          margin-right: 24px;
        }
      }
    }
  }

  .table-preview {
    th:first-child,
    td:first-child {
      width: 154px;
    }

    .cell {
      @include media-breakpoint-up(xxl) {
        &-bde,
        &-pv,
        &-pvm,
        &-power,
        &-delta,
        &-powermin,
        &-powermax {
          width: 10%;
        }
      }
    }
  }

  .alert {
    margin-bottom: 10px;
  }
}
