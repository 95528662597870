.card {
  background-color: $white;
  border-radius: $card-border-radius;
  box-shadow: $card-shadow;
  padding: $spacing-xs;

  &.no-pad {
    padding: 0;
  }

  &-border {
    border: solid 1.5px rgba(218, 223, 228, 0.58);
  }

  &.no-shadow {
    box-shadow: none;
  }
}
