$black: #000;
$black-alpha-1: rgba(0, 0, 0, 0.1);
$blue: #004d71;
$bordeaux: #771b21;
$concrete: #b4c6d4;
$coral: #ff5000;
$dark-blue: #013d5a;
$dark-concrete-2: #404f5c;
$dark-concrete: #4f606f;
$dark-coral-2: #e25413;
$dark-coral-3: #da4400;
$dark-coral: #e74900;
$dark-dust: #616f7a;
$dark-faded-coral-2: #ba602e;
$dark-faded-coral: #eeae92;
$dark-gray-2: #29323a;
$dark-gray-3: #171c20;
$dark-gray-4: #1b2127;
$dark-gray-alpha-5: rgba(51, 62, 72, 0.5);
$dark-gray: #333e48;
$dark-ice-gray: #ebeef7;
$dark-leaf: #1bc1a9;
$dark-sky-2: #2da1be;
$dark-sky-3: #0093b7;
$dark-sky: #2487a0;
$dust-2: #d8dde0;
$dust-3: #cbd3d9;
$dust-alpha-5: rgba(218, 223, 228, 0.5);
$dust: #dadfe4;
$dusty-concrete: #4a5a64;
$dusty-orange: #e97739;
$error: #ff516d;
$faded-coral-2: #ffc0a4;
$faded-coral-3: #ffdbcb;
$faded-coral: #ffa57d;
$faded-sky-2: #a5daea;
$faded-sky-3: #cdedf6;
$faded-sky-4: #eafbff;
$faded-sky: #89c6d8;
$gun-metal: #5c6670;
$ice-blue--high_contrast: #b3c1e1;
$ice-blue: #eceeff;
$ice-gray--high_contrast: #d6ddef;
$ice-gray: #f7f8ff;
$ice: #fbfcff;
$leaf: #3ce2d4;
$light-coral: #ff8d4e;
$light-dust: #eaeef1;
$light-error: #ffebee;
$light-success-2: #ecfff7;
$light-success: #d9f7eb;
$metal-gray: rgba(179, 193, 225, 0.55);
$pale-blue: #f1f4fe;
$pale-gray: #e3e9ee;
$purple-graph: #9d60fb;
$red: #e30039;
$sky--high_contrast: #0095bb;
$sky-2: #3ad7ff;
$sky-alpha-1: rgba(54, 185, 218, 0.1);
$sky-alpha-2: rgba(54, 185, 218, 0.2);
$sky: #36b9da;
$success-2: #00da72;
$success: #02d57c;
$white-sky: #f1f8fb;
$white: #fff;
$wolf-gray-2: #607283;
$wolf-gray-alpha-1: rgba(119, 137, 152, 0.1);
$wolf-gray-alpha-3: rgba(119, 137, 152, 0.3);
$wolf-gray: #778998;