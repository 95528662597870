.high-contrast {
  .typo {
    &-body-2 {
      font-size: 1.8rem ;
      font-style: normal;
      font-stretch: normal;
      font-kerning: normal;
      font-weight: bold;        
    }
    &-body-3 {
      font-size: 1.5rem;
    }
    &-textlink,
    &-textlink:active,
    &-textlink:link,
    &-textlink:visited {
      color: $sky--high_contrast;
    }
  }

  .table {
    &-head {
      background-color: $ice-blue--high_contrast;
    }

    &-body {
      &-row {        
        &:nth-child(even),
        &:nth-child(even):before {
          background-color: $ice-gray--high_contrast;
        }
      }

      &-cell {
        padding: 9px 0 5px;
      }
    }
  }

  .table-bde:not(.column-spread) .table-bde {
    &-head-cell,
    &-body-cell {
      &.cell-name { width: 168px;}
    }

    &-head-subcell,
    &-body-subcell {
      &.subcell-type            { width: 184px; }
      &.subcell-start-value     { width: 106px; }
      &.subcell-end-value       { width: 94px; }
      &.subcell-start-timestamp { width: 100px; }
      &.subcell-end-timestamp   { width: 94px; }
    }
  }

  .table-mib:not(.column-spread) .table-mib {
    &-head-cell,
    &-body-cell {
      &.cell-name {
        width: 170px;
      }
    }

    &-head-subcell,
    &-body-subcell {
      &.subcell-start-timestamp { width: 150px; }
      &.subcell-end-timestamp   { width: 150px; }
    }
  }
}