.table-report {
  $base-background-color: $white;
  $base-font-color: $dark-gray;
  $cell-width: 34px;
  $cell-height: 30px;
  $lip-size: 4px;
  $badge-size: 6px;

  width: 100%;

  &-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-body-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }

  &-body-cell {
    width: 100%;
    min-width: $cell-width;
    height: $cell-height;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $base-font-color;
    font-weight: bold;
    background-color: $base-background-color;
    box-shadow: $cell-shadow;
    border: solid $ice-gray 0.5px;
    border-radius: 4px;
    margin: 1px 0;
    position: relative;
    transition: background-color $animation-duration, background-image $animation-duration;
    cursor: pointer;

    &:hover {
      background-color: $ice-gray;
    }

    &.past {
      background-color: $base-background-color !important;
      color: $dark-gray-alpha-5 !important;
      border: solid $ice-gray 0.5px;
      box-shadow: none;
      font-weight: normal !important;
      opacity: 0.7;
      transition: opacity $animation-duration;

      &:before {
        background-color: $dust !important;
      }
    }

    &.past.offline {
      background: linear-gradient(135deg, $white 0%, $white 49%, $wolf-gray-alpha-3 50%, $wolf-gray-alpha-3 51%, $light-dust 52%, $light-dust 100%) !important;
    }

    /* &.changed {
      @extend .typo-bold;
    } */

    &.offline {
      background: linear-gradient(135deg, $white 0%, $white 49%, $wolf-gray-alpha-3 50%, $wolf-gray-alpha-3 51%, $light-dust 52%, $light-dust 100%);

      &:hover {
        background: linear-gradient(135deg, $ice-gray 0%, $ice-gray 49%, $wolf-gray-alpha-3 50%, $wolf-gray-alpha-3 51%, $light-dust 52%, $light-dust 100%);
      }
    }

    &.positive {
      background-color: $faded-sky-3;

      &:hover {
        background-color: $faded-sky-2;
      }
    }

    &.positive.offline {
      background: linear-gradient(135deg, $faded-sky-3 0%, $faded-sky-3 49%, $wolf-gray-alpha-3 50%, $wolf-gray-alpha-3 51%, $faded-sky-2 52%, $faded-sky-2 100%);

      &:hover {
        background: linear-gradient(135deg, $faded-sky-2 0%, $faded-sky-2 49%, $wolf-gray-alpha-3 50%, $wolf-gray-alpha-3 51%, $faded-sky 52%, $faded-sky 100%);
      }
    }

    &.negative {
      background-color: $faded-coral-3;

      &:hover {
        background-color: $faded-coral-2;
      }
    }

    &.negative.offline {
      background: linear-gradient(135deg, $faded-coral-3 0%, $faded-coral-3 49%, $wolf-gray-alpha-3 50%, $wolf-gray-alpha-3 51%, $faded-coral-2 52%, $faded-coral-2 100%);

      &:hover {
        background: linear-gradient(135deg, $faded-coral-2 0%, $faded-coral-2 49%, $wolf-gray-alpha-3 50%, $wolf-gray-alpha-3 51%, $dark-faded-coral 52%, $dark-faded-coral 100%);
      }
    }

    &.no-data {
      background-color: $light-dust;

      &:hover {
        background-color: $dust-3;
      }
    }

    &.badge {
      position: relative;
      
      &:before {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        width: $badge-size;
        height: $badge-size;
        background-color: $coral;
        border-radius: $badge-size;
      }
    }

    &.selected {
      border: solid $sky 1px;
      opacity: 1 !important;
    }
  }

  &-time {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: solid $dust-alpha-5 1px;
    margin-top: 4px;

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: $cell-width;
      width: 100%;
      text-align: center;
      padding: 2px 0 4px;
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }

      &.past {
        color: $dark-gray-alpha-5;
        font-size: 1.1rem;
      }

      &.current {
        @extend .typo-body-2-noquery;
        @extend .typo-bold;
        background-color: $sky-alpha-1;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
          width: 100%;
          height: $lip-size;
          background-color: $sky;
          border-top-left-radius: $lip-size;
          border-top-right-radius: $lip-size;
        }
      }
    }
  }

  &:hover {
    /* .table-report-time-item.past {
      color: $dark-gray-alpha-5 !important;
    } */

    .table-report-body-cell.past {
      opacity: 1;
    }
  }
}
