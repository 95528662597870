.checkbox {
  $checkmark-width: 18px;
  $checkmark-height: 17px;

  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;

  &-input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  &-checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: $checkmark-width;
    height: $checkmark-height;
    background-color: $white;
    border: solid $wolf-gray 1px;
    border-radius: 5px;
    transition: background-color $animation-duration;
  }

  &:hover,
  &:focus {
    .checkbox-checkmark {
      background-color: $white-sky;
    }

    .checkbox-checkmark:after {
      background-color: $dark-blue;
    }
  }

  &-input:checked ~ &-checkmark:after {
    opacity: 1;
  }

  &-checkmark:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: block;
    width: 10px;
    height: 10px;
    background-color: $dark-sky;
    border-radius: 2px;
    opacity: 0;
    transition: opacity $animation-duration, background-color $animation-duration;
  }
}