.table-bde {
  &-head-row,
  &-body-row {
    display: flex;
    justify-content: space-between;
  }

  &:not(.column-spread) {
    .table-bde-head-cell,
    .table-bde-body-cell {
      &.cell {
        &-name {
          width: 161px;
        }
      }
  
      &-group:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  
    .table-bde-head-subcell,
    .table-bde-body-subcell {
      display: inline-block;
      margin-left: 12px;
      text-align: right;
  
      &.subcell {
        &-type {
          width: 140px;
        }
  
        &-start-timestamp {
          width: 74px;
        }
  
        &-end-timestamp {
          width: 74px;
        }
  
        &-start-value {
          width: 88px;
        }
  
        &-end-value {
          width: 74px;
        }
      }
    }
  }

  &.column-spread {
    .table-body-cell,
    .table-bde-body-row {
      padding: 0;
    }

    .cell-data {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .table-bde-head-subcell,
      .table-bde-body-subcell {
        width: 100px;
      }
    }

    .table-bde-body-cell {
      &.cell-data {
        flex-direction: column;
      }

      &-group {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 10px 40px;

        @include media-breakpoint-down(lg) {
          padding: 10px 20px;
        }

        &:nth-child(even),
        &:nth-child(even):before {
          background-color: $ice-gray;
        }
      }
    }
    
    .table-bde-head-subcell.subcell-type,
    .table-bde-body-subcell.subcell-type {
      width: 200px;
    }
  }

}