.notebar {
  $notebar-open-height-dsk: calc( 100vh - (#{$header-height} + #{$page-top-spacing}) );
  $notebar-open-height-tab: calc( 100vh - #{$header-height} );
  $notebar-closed-height: 60px;
  $badge-size: 12px;

  position: fixed;
  bottom: 0;
  right: $page-spacing;
  width: $notebar-width;
  height: $notebar-closed-height;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 10;
  transition: height $animation-duration;

  @include media-breakpoint-down(lg) {
    right: -$notebar-width;
    height: $notebar-open-height-tab;
    border-top-right-radius: 0;
    transition: right $animation-duration;

    .notebar-header-top-chevron:before {
      transform: translate(-50%, -50%) rotateZ(90deg);
    }
  }

  &.open {
    height: $notebar-open-height-dsk;

    @include media-breakpoint-down(lg) {
      right: 0;
      height: $notebar-open-height-tab;
    }

    .notebar-header-top-chevron:before {
      transform: translate(-50%, -50%) rotateX(180deg);

      @include media-breakpoint-down(lg) {
        transform: translate(-50%, -50%) rotateX(180deg) rotateZ(90deg);
      }
    }
  }

  &-note-list {
    flex-grow: 1;
    overflow-y: scroll;
  }

  &-header-top {
    display: flex;
    align-items: center;
    padding: 10px 0;
    margin: 0 40px;
    cursor: pointer;

    &-subtitle {
      padding-left: 10px;
      margin-left: 10px;
      border-left: solid $dust 1px;
    }

    &-chevron {
      margin-left: auto;
    }

    &-badge {
      width: $badge-size;
      height: $badge-size;
      background-color: $coral;
      border-radius: $badge-size;
      margin-left: 12px;
    }
  }

  &-header-bottom {
    display: flex;
    align-items: center;
    padding: 8px 0;
    margin: 0 40px;
    border-top: solid $dust-alpha-5 1px;

    &-title {
      letter-spacing: 0.56px;
    }

    &-subtitle {
      padding-left: 10px;
      margin-left: 10px;
      border-left: solid $dust 1px;
    }
  }

  &-timestamp {
    margin: 0 40px 12px 40px;

    &-time {
      letter-spacing: 2.14px;
    }
    
    &-date {
      letter-spacing: 1px;
    }
  }

  &-helper {
    display: flex;
    background-color: $white-sky;
    padding: 10px 40px;
    /* margin-bottom: 15px; */

    &.hidden {
      display: none;
    }

    &-icon {
      margin-right: 20px;
    }

    &-content {
      width: 254px;
      line-height: 1.29;
    }

    &-close {
      align-self: center;
      margin-left: auto;
      cursor: pointer;
    }
  }

  &-note-group {
    display: flex;
    align-items: center;
    padding: 8px 20px;
    background-color: $white-sky;
    border-radius: 5px;
    margin: 0 40px 12px 40px;

    &-subtitle {
      padding-left: 10px;
      margin-left: 10px;
      border-left: solid $dust 1px;
    }
  }

  &-note {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 40px 12px 40px;
    padding: 10px 15px;

    &:hover {
      .notebar-note-delete,
      .notebar-note-edit {
        visibility: visible;
        opacity: 1; 
        margin-left: 15px;
        margin-right: 10px;
      }
    }

    &-content {
      margin-bottom: 20px;
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: solid $dust 1px;
      padding-top: 10px;
    }

    &-owner {
      flex-grow: 1;
    }

    &-delete,
    &-edit {
      visibility: hidden;
      width: 0 !important;
      opacity: 0;
      margin-left: 0;
      cursor: pointer;
    }

    &-timestamp {
      text-transform: capitalize;
    }

    &-confirm-delete {
      display: flex;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: max-content;
      padding: 10px 20px;
      transition: opacity $animation-duration;

      &-actions {
        display: flex;
        align-items: center;
        margin-left: 40px;
      
        .btn {
          padding: 4px;
          font-size: 1rem;
          width: 32px;
          height: 32px;
          border-radius: 6px;

          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }

    &.show-confirm-delete {
      .notebar-note-confirm-delete {
        visibility: visible;
        opacity: 1;
      }

      .notebar-note-delete,
      .notebar-note-edit {
        cursor: inherit;
        pointer-events: none;
      }
    }
  }

  &-button-add {
    margin: 10px 40px 20px 40px;
  }

  &-input {
    padding: 0 40px;
    visibility: hidden;
    transition: height $animation-duration;
    height: 0;
    overflow: hidden;

    &.show {
      visibility: visible;
      height: 234px;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;

      .btn {
        width: 193px;
      }
    }

    &-field {
      width: 100%;
      height: 133px;
      margin-bottom: 15px;
      resize: none;
    }
  }

  &-alert-box {
    margin: 0 40px;
    position: relative;

    .alert {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 5;
    }
  }

  &-tablet-open-button {
    display: none;
    position: fixed;
    bottom: 16px;
    right: 32px;
    z-index: 9;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}