.auction-page {  
  position: relative;

  &-content {
    width: 100%;
    
    &.notebar-opened {
      width: calc( 100% - #{$notebar-width} - #{$page-spacing} );

      @include media-breakpoint-down(lg) {
        width: 100%;  
      }
    }
  }

  .dropdown {
    margin-bottom: $spacing-md;
  }

  .card {
    &-table-preview {
      margin-bottom: 15px;

      .cell {
        &-name {
          width: 200px;
        }

        &-data {
          margin-left: 15px;
          width: 60px;
        }

        @include media-breakpoint-up(xxl) {
          &-bde,
          &-pv,
          &-pvm,
          &-power,
          &-delta,
          &-powermin,
          &-powermax {
            width: 10%;
          }
        }
      }
    }

    &-table-report {
      flex-grow: 1;
      margin-bottom: 5px;

      &-unavailable {
        padding: 10px 40px;
        display: flex;
        align-items: center;

        &-subtitle {
          margin-left: 20px;
        }
      }
    }
  }

  .accordion-bde {
    .accordion {
      &-head {
        justify-content: center;
        padding: 5px 0;
  
        &-content {
          flex-grow: unset;
        }
      }

      &-chevron {
        margin-left: 5px;

        &:before {
          background-color: $sky;
        }

        &:hover:before {
          background-color: $dark-sky !important;
        }
      }
    }
  }

  &-filters {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    top: $header-height;
    z-index: 8;
    
    &.sticky {
      position: sticky;
      background-color: $ice-gray;
      margin: 0 -15px;
      padding: 12px 15px 12px 15px;
      border: solid 1px #e3e3e3;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: $card-shadow;
    }
  }

  &-guide {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &-list {
      display: flex;
      align-items: center;

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;

        @include media-breakpoint-down(lg) {
          margin-left: 15px;

          &:first-child {
            margin-left: 0;
          }
        }

        &-example {
          @extend .card;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 23px;
          padding: 0px;
          border-radius: 5px;
          margin-right: 12px;

          @include media-breakpoint-down(lg) {
            margin-right: 8px;
          }

          &.positive {
            background-color: $faded-sky-3;
          }

          &.negative {
            background-color: $faded-coral-3;
          }

          &.no-data {
            background-color: $light-dust;
          }

          &.past {
            color: $dark-gray-alpha-5;
            box-shadow: none;
            border: solid 0.5px $dust;
          }

          &.mib {
            background: linear-gradient(135deg, $white 0%, $white 49%, $wolf-gray-alpha-3 50%, $wolf-gray-alpha-3 51%, $light-dust 52%, $light-dust 100%);
          }
        }
      }
    }

    &-title {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .card-table-report-container {
    display: flex;

    @include media-breakpoint-down(lg) {
      display: block;
    }

    .card-total-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 180px;
      padding: 10px 0;
      margin-left: 20px;
      margin-bottom: 5px;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      &-fieldgroup {
        display: flex;
        
        &.vertical {
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
      }
      
      &-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-value:not(.extend) {
        margin-left: 4px;
      }

      .separator {
        display: inline-block;
        width: 100%;
        border-bottom: 1px $dust-alpha-5 solid;
        text-align: center;
      }

      .extend {
        flex-grow: 1;
      }

      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .chart-zoom {
    margin-left: auto;
  }

  &.view-all {
    .accordion-group {
      &>.accordion-head {
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: $card-border-radius;
      }
      &-ven>.accordion-head { background-image: linear-gradient(to right, antiquewhite 6%, $white 75%); }
      &-ult>.accordion-head { background-image: linear-gradient(to right, #e2f1dd 6%, $white 75%); }
      &-pus>.accordion-head { background-image: linear-gradient(to right, #F4F0BF 6%, $white 75%); }
      &-san>.accordion-head { background-image: linear-gradient(to right, $ice-gray 6%, $white 75%); }
      &-isa>.accordion-head { background-image: linear-gradient(to right, $faded-sky-3 6%, $white 75%); }
    }
  }
}

body:not(.notebar-visible) .auction-page {
  .card-table-preview {
    @include media-breakpoint-up(lg) {
      .cell {
        &-name {
          width: 250px;
        }

        &-icon {
          width: 32px;
        }

        &-bde,
        &-pv,
        &-pvm,
        &-power,
        &-delta,
        &-powermin,
        &-powermax {
          width: 110px;
        }
      }
    }
  }
}