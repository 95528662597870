ngb-datepicker {
  $datepicker-width: 260px;

  width: $datepicker-width;
  background-color: $white;
  border: solid $dust-alpha-5 1px !important;
  border-radius: 5px !important;
  box-shadow: $card-shadow;

  .ngb-dp-header {
    margin: 10px 0 15px;
  }

  .ngb-dp-navigation-select>select:nth-child(1) {
    text-align: center;
    border: 0;
    font-weight: bold;
    font-size: 1.4rem;
    background-color: $white;
  }

  ngb-datepicker-month {
    width: $datepicker-width;
    padding-bottom: 10px;
    font-size: 1.2rem;
  }

  .ngb-dp-week {
    padding: 0 15px !important;
  }

  .ngb-dp-weekdays {
    border: none;
    margin-bottom: 10px;
  }

  .ngb-dp-weekday {
    font-style: normal;
    text-transform: uppercase;
    color: $sky;
  }

  .ngb-dp-weekday,
  .ngb-dp-day {
    width: 100%;
  }

  .ngb-dp-day>.btn-light {
    width: 100%;
  }

  .ngb-dp-day>.btn-light.bg-primary {
    position: relative;
    color: $white;
    font-weight: bold;
    background-color: transparent !important;

    &:before {
      $today-badge-size: 22px;

      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: $today-badge-size;
      height: $today-badge-size;
      background-color: $sky;
      border-radius: $today-badge-size;
      z-index: -1;
    }
  }

  .ngb-dp-today {
    position: relative;
    font-weight: bold;

    &:before {
      $today-badge-size: 22px;

      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: $today-badge-size;
      height: $today-badge-size;
      border: solid $sky 1px;
      border-radius: $today-badge-size;
      z-index: -1;
    }
  }
}