.header {
  $tablet-header-width: 318px;

  width: 100%;
  height: $header-height;
  background-color: $white;
  box-shadow: $card-shadow;
  position: fixed;
  z-index: 999;

  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &-logo {
    width: 90px;
    height: 30px;
  }

  &-left,
  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-burger {
    display: none;
    
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  &-menu {
    display: flex;

    @include media-breakpoint-down(lg) {
      position: absolute;
      top: $header-height;
      left: -$tablet-header-width; 
      width: $tablet-header-width;
      height: calc( 100vh - #{$header-height} );
      background-color: $white;
      flex-direction: column;
      padding: 30px 0;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      box-shadow: none;
      transition: left $animation-duration, box-shadow $animation-duration;

      &.open {
        left: 0;
        box-shadow: $card-shadow;

        .header-menu-overlay {
          opacity: 0.2;
        }
      }

      &:not(.open) {
        .header-menu-overlay {
          pointer-events: none;
        }
      }
    }

    &-item {
      padding: 10px 15px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      margin-right: 15px;
      transition: color $animation-duration, background-color $animation-duration;

      @include media-breakpoint-down(lg) {
        width: 100%;
        padding: 10px 48px;
        border-radius: 0;
        margin: 0 0 10px 0;
      }

      &:hover {
        color: $sky;

        .header-menu-item-icon:before {
          background-color: $sky;
        }
      }

      &.active {
        background-color: $sky;
        color: $white !important;
        font-weight: bold;

        &:hover {
          background-color: $dark-sky;
        }

        .header-menu-item-icon:before {
          background-color: $white;
        }
      }

      &-icon {
        margin-left: 10px;
        transition: color $animation-duration, background-color $animation-duration;

        &:before {
          transition: color $animation-duration, background-color $animation-duration;
        }
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: calc( 100vw + #{$tablet-header-width} );
      height: calc( 100vh - #{$header-height} );
      background-color: $dark-gray;
      opacity: 0;
      z-index: -1;
      transition: opacity $animation-duration;
    }
  }

  &-user {
    position: relative;
    margin-left: 50px;

    &-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    &-icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $light-success;
      border: solid $success 2px;
      border-radius: 50%;
    }

    &-chevron {
      margin-left: 10px;
    }

    &:hover .header-user-chevron {
      @extend .icon-coral;
    }

    &-menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 50px;
      right: 0;
      width: 200px;
      padding: 10px 12px;
      cursor: initial;

      &-item {
        &:not(:first-child) {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-top: 8px;
          cursor: pointer;
        }

        &-name {
          border-bottom: solid $dust 1px;
        }

        &-logout {
          align-items: center;
        }

        i {
          margin-left: 10px;
        }

        &.disabled {
          opacity: 0.5;
          cursor: default;
          pointer-events: none;
        }
      }
    }

    &.open {
      .header-user-menu {
        display: flex;
      }

      .header-user-chevron {
        transform: rotateX(180deg);
      }
    }
  }

  &-language {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;

    @include media-breakpoint-down(lg) {
      padding-top: 30px;
      padding-left: 30px;
      border-top: solid $dust 1px;
      margin: 50px 15px 0 15px;
    }

    &-item {
      &.active {
        color: $dark-gray;
      }
    }

    &-separator {
      color: $dust;
    }
  }

  &-logout {
    margin-left: 15px;
  }

  &-clock {
    display: flex;
    align-items: center;
    margin-right: 25px;
    padding: 10px 15px;
    border-radius: 10px;
    border: solid 1.5px $dust;
  }

  &-external-link {
    margin-right: 24px;
  }
}
