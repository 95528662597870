.chart-doughnut {
  $legend-sample-size: 8px;
  $track-width: 200px;
  $track-height: 200px;
  $track-thickness: 20px;

  &-canvas-wrapper {
    position: relative;
    margin: auto;
  }

  &-info,
  &-track {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-info {
    z-index: 2;

    &-percentage {
      display: flex;
      justify-content: center;
      align-items: center;

      &-value {
        line-height: 1;
      }
    }

    &-duration {
      display: flex;

      &-since,
      &-value {
        margin-right: 3px;
      }
    }
  }

  &-track {
    width: $track-width;
    height: $track-height;
    background-color: transparent;
    border: solid $ice-blue $track-thickness;
    border-radius: $track-width;
    z-index: 0;
  }

  canvas {
    position: relative;
    z-index: 1;
  }

  &-legend {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    &-item {
      display: flex;
      align-items: center;

      &-sample {
        width: $legend-sample-size;
        height: $legend-sample-size;
        border-radius: $legend-sample-size;
        margin-right: 4px;

        &.nominal {
          background-color: $sky;
        }

        &.no-data {
          background-color: $wolf-gray;
        }

        &.warning {
          background-color: $coral;
        }

        &.critical {
          background-color: $red;
        }
      }
    }
  }
}