.input-select {
  position: relative;
  width: 100%;

  &.open {
    .input-select-chevron {
      transform: rotateX(180deg);
    }

    .input-select-list {
      display: flex;
    }
  }

  &-current {
    padding: 10px;
    background-color: $white;
    border: solid $sky 1px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    @extend .typo-body-1;

    &:hover,
    &:focus {
      transition: background-color $animation-duration;
      background-color: $white-sky;
    }

    &-value {
      line-height: 1.2;
    }
  }

  &-chevron {
    transition: transform $animation-duration;
  }

  &-list {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: 12px 0;
    position: absolute;
    background-color: $white;
    border-radius: 10px;
    overflow-y: auto;
    box-shadow: $card-shadow;
    z-index: 1;

    &-item {
      width: 100%;
      padding: 8px 0;
      text-align: center;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: $white-sky;
        @extend .typo-bold;
      }
    }
  }
}