.table-mib {
    &-head-row,
    &-body-row {
      display: flex;
      justify-content: space-between;
    }
  
    &:not(.column-spread) {
      .table-mib-head-cell,
      .table-mib-body-cell {
        &.cell {
          &-name {
            width: 161px;
          }
        }
    
        &-group:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    
      .table-mib-head-subcell,
      .table-mib-body-subcell {
        display: inline-block;
        margin-left: 12px;
        text-align: right;
    
        &.subcell {
          &-start-timestamp {
            width: 120px;
          }
    
          &-end-timestamp {
            width: 120px;
          }
        }
      }
    }
  
    &.column-spread {
      .table-body-cell,
      .table-mib-body-row {
        padding: 0;
      }
  
      .cell-data {
        display: flex;
        justify-content: space-between;
        width: 100%;
  
        .table-mib-head-subcell,
        .table-mib-body-subcell {
          width: 100px;
        }
      }
  
      .table-mib-body-cell {
        &.cell-data {
          flex-direction: column;
        }
  
        &-group {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding: 10px 40px;
  
          &:nth-child(even),
          &:nth-child(even):before {
            background-color: $ice-gray;
          }
        }
      }
      
      .table-mib-head-subcell.subcell-type,
      .table-mib-body-subcell.subcell-type {
        width: 200px;
      }
    }
  
  }