.modal {
  width: 100%;
  padding: 20px 40px;
  margin: auto;

  @include media-breakpoint-down(lg) {
    padding: 20px;
  }

  &-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: solid $dust 1px;
    margin-bottom: 10px;

    &-content {
      flex-grow: 1;
    }

    &-inner {
      display: flex;
      align-items: center;
    }

    &-subtitle {
      margin-left: 20px;
    }
  }

  &-close {
    margin-left: 10px;
    border: none;

    &:before {
      transition: transform $animation-duration;
    }
  }

  &-wrapper {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: calc( 100% - #{$header-height} );
    margin-top: $header-height;
    visibility: hidden;
    opacity: 0;
    transition: opacity $animation-duration;
    z-index: 100;

    &.modal-open {
      visibility: visible;
      opacity: 1;

      &.modal-overlay {
        visibility: visible;
        opacity: 0.3;
      }
    }
  }

  &-container {
    position: absolute;
    top: calc( 50vh - ( #{$header-height} / 2 ) );
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $dark-gray;
    opacity: 0.3;
    z-index: -1;
  }
}

.modal-chart-zoom {
  &-body-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart-footer {
    margin-bottom: 0;
  }
}

.modal-config {
  width: fit-content;

  &-head-select {
    width: 250px;
    margin-left: 15px;
  }

  &-loader {
    min-height: 100px;
    min-width: 200px;
    position: relative;
    @extend .loader-container;
    opacity: 1 !important;

    &:after {
      @extend .loader-spinner;
    }
  }
}

/* .modals-container {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 105;

  &.show {
    display: block;
  }
}

.modal {
  display: none;
  width: 100%;

  &.show {
    display: block;
  }
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: calc( 100% - #{$header-height} );
  background-color: $dark-gray;
  visibility: hidden;
  opacity: 0;
  transition: opacity $animation-duration;
  z-index: 100;

  &.visible {
    visibility: visible;
    opacity: 0.3;
  }
} */