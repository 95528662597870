.table {
  $hover-extrude: 8px;

  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &-head-row,
  &-body-row {
    display: flex;
    flex-direction: row;
    padding: 0 40px;

    @include media-breakpoint-down(lg) {
      padding: 0 20px;
    }
  }

  &-head {
    background-color: $ice-blue;

    &-cell {
      padding: 8px 0px;
    }
  }

  &-body {
    &-row {
      position: relative;
      
      &:nth-child(even),
      &:nth-child(even):before {
        background-color: $ice-gray;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: calc( #{$hover-extrude} * -1 );
        height: 100%;
        width: calc( 100% + ( #{$hover-extrude} * 2 ) );
        background-color: $white;
        box-shadow: $card-shadow;
        border-radius: 6px;
        opacity: 0;
        z-index: 1;
        transition: opacity $animation-duration;
      }

      &:hover:before {
        opacity: 1;
      }
    }

    &-cell {
      padding: 10px 0px;
      position: relative;
      z-index: 1;
      line-height: 1;
    }
  }
}