.input-textarea {
  background-color: $white;
  border: solid $dust 1px;
  border-radius: 8px;
  padding: 8px;

  &::placeholder {
    color: $dust;
  }

  &:focus,
  &:active {
    outline-color: $sky;
  }
}