.tab {
  background-color: $white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
  position: relative;
  z-index: 1;

  &-item {
    width: 120px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gun-metal;
    transition: color $animation-duration;
    position: relative;
    z-index: 4;
    cursor: pointer;

    @include media-breakpoint-down(lg) {
      width: 90px;
    }

    &:not(:first-child):before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 10px;
      background-color: $dust;
      z-index: 2;
    }

    &:hover {
      color: $sky;
    }

    &.active {
      @extend .card;
      @extend .no-pad;
      @extend .typo-heading-4;
      color: $coral;

      + .tab-item:before {
        background-color: transparent;
      }

      &:before {
        display: none;
      }

      &:hover {
        color: $dark-coral;
      }
    }
  }
}