.auction-config {
  position: relative;

  &-heading {
    margin-bottom: 15px;

    &:not(:first-child) {
      margin-top: 50px;
    }
  }

  &-field {
    &-set {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    &-label {
      width: 150px;
    }

    &-input {
      width: 400px;
    }

    &-controls {
      margin-left: 30px;

      i:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &-alert-box {
    position: absolute;
    top: -140px;
    left: 0;
    width: 100%;
  }
}